import React, { useState, useEffect } from "react";

const formatDate = (dateString, lang = "tr") => {
  const lang_mapping = {
    tr: "tr-TR",
    en: "en-EN",
  };

  const date = new Date(dateString);
  return date.toLocaleDateString(lang_mapping[lang], {
    year: "numeric",
    month: "long",
    day: "numeric",
  });
};

const DateComponent = ({ dateString }) => {
  const language = localStorage.getItem("i18nextLng") || "tr";

  useEffect(() => {}, [language]);

  const formattedDate = formatDate(dateString, language);
  return <div>{formattedDate}</div>;
};

export default DateComponent;
