import { useUser } from "./UserProvider";

import { formatPrice } from "../utils/formats";

const ProductDetailPreview = ({ data }) => {
  const { user } = useUser();
  console.log(user);
  const renderImage = () => {
    if (data.images && data.images.length > 0) {
      return (
        <img
          src={data.images[0]?.url}
          alt={data.name || "Product Image"}
          className="w-full h-[500px] object-cover rounded"
        />
      );
    }
    return (
      <div className="w-full h-[500px] bg-gray-300 rounded animate-pulse flex items-center justify-center">
        <span className="text-gray-500 text-xs">No Image</span>
      </div>
    );
  };

  if (!data) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-gray-600 text-xl">Ürün bulunamadı</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Üst Başlık */}
      <div className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 py-6">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
            <div>
              <h1 className="text-2xl md:text-3xl font-bold text-gray-900">
                {data.title}
              </h1>
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Sol: Fotoğraflar */}
          <div className="lg:col-span-8">
            <div className="bg-white rounded-lg shadow-sm p-4">
              {/* Ana Fotoğraf */}
              <div className="relative mb-4">
                {renderImage()}
                <div className="absolute bottom-4 right-4 bg-black/70 text-white px-4 py-2 rounded-md flex items-center gap-2 hover:bg-black/80">
                  <span>Tüm fotoğrafları görün</span>
                </div>
              </div>

              {/* Thumbnail'lar */}
              <div className="grid grid-cols-4 gap-4">
                {data.images?.slice(0, 9).map((img, index) => (
                  <div
                    key={index}
                    className={`flex-1 aspect-[4/3] relative rounded-lg overflow-hidden
                              ${
                                "ring-2 ring-blue-500"
                                //   "hover:ring-2 hover:ring-gray-300"
                              }`}
                  >
                    <img
                      src={img.url}
                      alt={`${data.title} ${index + 1}`}
                      className="w-full h-full object-cover"
                    />
                  </div>
                ))}
                {/* {data.images?.length > 4 && (
                  <div
                    className="flex-1 aspect-[4/3] relative rounded-lg overflow-hidden cursor-pointer bg-black/50 hover:bg-black/60"
                    onClick={() => alert("Tüm fotoğrafları görüntüle")}
                  >
                    <img
                      src={data.images[4].url}
                      alt={`${data.title} 5`}
                      className="w-full h-full object-cover opacity-50"
                    />
                    <div className="absolute inset-0 flex items-center justify-center text-white font-medium">
                      +{data.images.length - 4}
                    </div>
                  </div>
                )} */}
              </div>
            </div>
          </div>

          {/* Sağ: Detaylar ve İletişim */}
          <div className="lg:col-span-4 space-y-6 relative">
            {/* Fiyat ve İletişim Kartı */}
            <div className="bg-white rounded-lg shadow-sm p-6">
              <div className="space-y-6">
                <div>
                  <span className="text-sm text-gray-500">Fiyat</span>
                  <div className="text-2xl font-bold text-gray-900">
                    {formatPrice(data.price, data.currency)}
                  </div>
                </div>

                <div className="border-t pt-6">
                  <h3 className="font-semibold text-lg mb-4">
                    Satıcı Bilgileri
                  </h3>
                  <div className="space-y-4">
                    <div>
                      <p className="text-gray-600">{user.full_name}</p>
                      <p className="text-gray-600">+{user.phone_number}</p>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <div className="bg-white rounded-lg shadow-sm p-6">
              <div className="space-y-6">
                <h3 className="font-semibold text-lg mb-4">Adres</h3>
                <div className="space-y-4">
                  <div>
                    <p className="text-gray-600">{data.address}</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Teknik Özellikler */}
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-semibold mb-4">Teknik Özellikler</h2>
              <div className="space-y-3">
                {data.attributes?.map((attr) => (
                  <div
                    key={attr.id}
                    className="flex justify-between py-2 border-b"
                  >
                    <span className="text-gray-600">{attr.display_name}</span>
                    <span className="font-medium">{attr.value}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Açıklama */}
            {data.description && (
              <div className="bg-white rounded-lg shadow-sm p-6">
                <h2 className="text-xl font-semibold mb-4">Açıklama</h2>
                <p className="text-gray-600 whitespace-pre-line">
                  <div dangerouslySetInnerHTML={{ __html: data.description }} />
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailPreview;
