import React, { useState } from "react";

const faqs = [
  {
    question: "VincBurada nedir ve nasıl çalışır?",
    answer:
      "VincBurada, iş makineleri alım-satım ve kiralama platformudur. Ücretsiz üye olabilir, ilan verebilir ve makine satın alabilirsiniz.",
  },
  {
    question: "Nasıl ilan verebilirim?",
    answer:
      "Üye girişi yaptıktan sonra 'İlan Ver' butonuna tıklayarak ilanınızı oluşturabilirsiniz.",
  },
  {
    question: "İlanlar ne kadar süre yayında kalır?",
    answer:
      "Standart ilanlar 30 gün boyunca yayında kalır. Ücretli ilanlar için süre uzatılabilir.",
  },
  {
    question: "Ödemeleri nasıl yapıyoruz?",
    answer:
      "Şu an için VincBurada sadece alıcı ve satıcıyı bir araya getirmektedir. Ödeme işlemleri platform dışı gerçekleşir.",
  },
  {
    question: "Satıcılarla nasıl iletişime geçebilirim?",
    answer:
      "İlan detay sayfasında bulunan 'Mesaj Gönder' butonu ile doğrudan satıcıyla iletişime geçebilirsiniz.",
  },
];

const HelpCenter = () => {
  const [openIndex, setOpenIndex] = useState(-1);

  const toggleFAQ = (index) => {
    setOpenIndex(openIndex === index ? null : index);
  };

  return (
    <div className="max-w-3xl mx-auto p-12">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">
        Sıkça Sorulan Sorular (SSS)
      </h1>
      <div className="space-y-4">
        {faqs.map((faq, index) => (
          <div
            key={index}
            className="border border-gray-300 rounded-lg overflow-hidden shadow-sm"
          >
            <button
              className="w-full p-4 flex justify-between items-center text-left bg-white hover:bg-gray-50"
              onClick={() => toggleFAQ(index)}
            >
              <span className="font-medium text-gray-800">{faq.question}</span>
              <span className="text-xl">{openIndex === index ? "−" : "+"}</span>
            </button>
            {openIndex === index && (
              <div className="p-4 bg-gray-50 text-gray-700">{faq.answer}</div>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

export default HelpCenter;
