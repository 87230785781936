import React from "react";
import { Link } from "react-router-dom";

import { useTranslation } from "react-i18next";

const SectionSelection = ({ categoriesRef }) => {
  const { t } = useTranslation();

  // scroll function
  const scrollToSection = (ref) => {
    window.scrollTo({
      top: ref.current.offsetTop,
      behavior: "smooth",
    });
  };

  return (
    <div className="md:px-52 md:pt-20 flex justify-center gap-6 mb-10 flex-wrap">
      {/* İlk Kart */}
      <div className="w-full md:w-2/5 relative group overflow-hidden rounded-lg shadow-lg">
        <img
          className="w-full h-64 object-cover transition-transform duration-300 group-hover:scale-110 brightness-50"
          src="./media/selection1.jpg"
          alt="selection1"
        />
        <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black via-transparent to-transparent p-6">
          <h2 className="text-white text-xl font-bold">
            {t("sectionSelectionTitle1")}
          </h2>
          <Link
            to="/profile/create-listing"
            className="inline-block mt-4 px-4 py-2 bg-blue-500 text-white text-sm font-semibold rounded hover:bg-blue-600"
          >
            {t("sectionSelectionButton1")}
          </Link>
        </div>
      </div>

      {/* İkinci Kart */}
      <div className="w-full md:w-2/5 relative group overflow-hidden rounded-lg shadow-lg">
        <img
          className="w-full h-64 object-cover transition-transform duration-300 group-hover:scale-110 brightness-50"
          src="./media/selection2.jpg"
          alt="selection2"
        />
        <div className="absolute bottom-0 left-0 w-full bg-gradient-to-t from-black via-transparent to-transparent p-6">
          <h2 className="text-white text-xl font-bold">
            {t("sectionSelectionTitle2")}
          </h2>
          <button
            onClick={() => scrollToSection(categoriesRef)}
            className="inline-block mt-4 px-4 py-2 bg-blue-500 text-white text-sm font-semibold rounded hover:bg-blue-600"
          >
            {t("sectionSelectionButton2")}
          </button>
        </div>
      </div>
    </div>
  );
};

export default SectionSelection;
