import React, { useState } from "react";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const ProfileNavbar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();

  return (
    <div className="bg-white border-b border-gray-200 w-full">
      {/* Mobile Menu Button */}
      <div className="md:hidden flex items-center justify-between p-4">
        <h1 className="text-lg font-bold">{t("profile")}</h1>
        <button
          onClick={() => setIsOpen(!isOpen)}
          className="text-gray-800 focus:outline-none"
        >
          ☰
        </button>
      </div>

      {/* Mobile Dropdown */}
      {isOpen && (
        <div className="md:hidden bg-gray-50">
          <Link
            to="/profile"
            className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
          >
            {t("accountDetails")}
          </Link>
          <Link
            to="/profile/change-password"
            className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
          >
            {t("changePassword")}
          </Link>
          <Link
            to="/profile/my-listings"
            className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
          >
            {t("myListings")}
          </Link>
          <Link
            to="/profile/favorites"
            className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
          >
            {t("myFavorites")}
          </Link>
          <Link
            to="/profile/messages"
            className="block px-4 py-2 text-gray-800 hover:bg-gray-100"
          >
            {t("myMessages")}
          </Link>
        </div>
      )}

      {/* Desktop Navigation */}
      <div className="hidden md:flex container mx-auto divide-x divide-gray-200">
        <Link
          to="/profile"
          className="px-4 py-2 text-gray-800 hover:bg-gray-100 hover:text-gray-900 flex-1 text-center"
        >
          {t("accountDetails")}
        </Link>
        <Link
          to="/profile/change-password"
          className="px-4 py-2 text-gray-800 hover:bg-gray-100 hover:text-gray-900 flex-1 text-center"
        >
          {t("changePassword")}
        </Link>
        <Link
          to="/profile/my-listings"
          className="px-4 py-2 text-gray-800 hover:bg-gray-100 hover:text-gray-900 flex-1 text-center"
        >
          {t("myListings")}
        </Link>
        <Link
          to="/profile/favorites"
          className="px-4 py-2 text-gray-800 hover:bg-gray-100 hover:text-gray-900 flex-1 text-center"
        >
          {t("myFavorites")}
        </Link>
        <Link
          to="/profile/messages"
          className="px-4 py-2 text-gray-800 hover:bg-gray-100 hover:text-gray-900 flex-1 text-center"
        >
          {t("myMessages")}
        </Link>
      </div>
    </div>
  );
};

export default ProfileNavbar;
