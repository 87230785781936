import React from "react";
import { Link, useNavigate } from "react-router-dom";
import DateComponent from "./DateComponent";

import { motion } from "framer-motion";
import { FiChevronRight, FiSearch } from "react-icons/fi";
import { useTranslation } from "react-i18next";

const containerVariants = {
  hidden: { opacity: 0 },
  visible: {
    opacity: 1,
    transition: {
      staggerChildren: 0.2,
    },
  },
};

const itemVariants = {
  hidden: { opacity: 0, y: 20 },
  visible: {
    opacity: 1,
    y: 0,
    transition: {
      duration: 0.6,
    },
  },
};

const HeroSection = ({ machine, layout }) => {
  const { t } = useTranslation();
  const [searchQuery, setSearchQuery] = React.useState("");
  const navigate = useNavigate();

  const handleSearch = (event) => {
    event.preventDefault();
    if (!searchQuery.trim()) return;

    navigate(`/search?q=${searchQuery}`);
  };
  return (
    <div className="relative h-[650px] w-full bg-[#1a1c1e] overflow-hidden">
      <video
        className="top-0 left-0 min-w-full min-h-full object-cover absolute z-1 opacity-20"
        src="./media/hero.mp4"
        autoPlay
        loop
        muted
        playsInline
      ></video>
      {/* Content */}
      <motion.div
        variants={containerVariants}
        initial="hidden"
        animate="visible"
        className="relative h-full max-w-7xl mx-auto px-4 flex flex-col items-center justify-center"
      >
        <motion.h1
          variants={itemVariants}
          className="text-5xl md:text-6xl font-display font-bold text-center mb-6 text-white tracking-tight"
        >
          {t("heroTitle")}
        </motion.h1>

        <motion.p
          variants={itemVariants}
          className="text-xl font-sans text-center mb-12 text-gray-300 max-w-2xl leading-relaxed"
        >
          <motion.span
            initial={{ opacity: 0, y: 50 }}
            animate={{ opacity: 1, y: 0 }}
            transition={{ delay: 0.5, duration: 0.5 }}
          >
            {t("heroSubtitle")}
          </motion.span>
        </motion.p>

        {/* Search Box */}
        <div className="w-full max-w-3xl bg-white rounded-2xl shadow-2xl">
          <form onSubmit={handleSearch} className="relative">
            <div className="flex items-center bg-gray-50 rounded-xl border border-gray-200">
              <input
                type="text"
                value={searchQuery}
                onChange={(e) => setSearchQuery(e.target.value)}
                placeholder={t("heroSearchPlaceholder")}
                className="w-full px-6 py-4 bg-transparent
                           text-gray-900 text-lg placeholder-gray-400
                           focus:outline-none"
              />
              <button
                type="submit"
                className="mr-2 bg-blue-600 hover:bg-blue-700 text-white 
                           px-8 py-3 rounded-lg
                           transition-all duration-300 
                           flex items-center gap-2
                           font-medium"
              >
                <FiSearch className="w-5 h-5" />
                <span>{t("search")}</span>
              </button>
            </div>
          </form>
        </div>
      </motion.div>
    </div>
  );
};

export default HeroSection;
