import api from "./api";

export const sendContactMail = async (data) => {
  try {
    const response = await api.post("/contact/", data);
    return response;
  } catch (error) {
    console.error("Error creating machine:", error);
    throw error;
  }
};
