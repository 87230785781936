import axios from "axios";

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL + "/api",
});

api.interceptors.request.use(
  (config) => {
    const accessToken = localStorage.getItem("access");
    if (accessToken) {
      config.headers.Authorization = `Bearer ${accessToken}`;
    }

    const lang = localStorage.getItem("i18nextLng");
    if ((typeof lang === "string") & (lang.length <= 2)) {
      config.headers["Accept-Language"] = lang;
    } else {
      config.headers["Accept-Language"] = "tr";
    }
    return config;
  },
  (error) => Promise.reject(error)
);

api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response?.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      try {
        const refreshToken = localStorage.getItem("refresh");
        if (refreshToken) {
          const response = await axios.post(
            "https://django-backend-gxpx5h2wha-ew.a.run.app/api/token/refresh/",
            {
              refresh: refreshToken,
            }
          );
          localStorage.setItem("access", response.data.access);
          api.defaults.headers.Authorization = `Bearer ${response.data.access}`;
          return api(originalRequest);
        }
      } catch (refreshError) {
        console.error("Token refresh failed:", refreshError);
        localStorage.clear();
        window.location.href = "/login"; // Redirect to login on token expiration
      }
    }
    return Promise.reject(error);
  }
);

export default api;
