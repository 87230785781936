import { Route, Routes, useLocation } from "react-router-dom";
import ProtectedRoute from "./ProtectedRoute.jsx";

import ProfilePage from "./ProfilePage.jsx";
import ChangePassword from "./ChangePassword.jsx";
import FavoritesPage from "./FavoritesPage.jsx";
import MyListingsPage from "./MyListingsPage.jsx";
import CreateListingPage from "./CreateListingPage.jsx";
import MessagesPage from "./MessagesPage.jsx";
import ProfileNavbar from "./ProfileNavbar";

const Profile = () => {
  const location = useLocation();

  // Check if the current route is `/profile/create-listing`
  const hideNavbarRoutes = ["/profile/create-listing"];
  const shouldHideNavbar = hideNavbarRoutes.includes(location.pathname);

  return (
    <div className="flex md:px-52 md:py-10">
      <div className="flex-1">
        {/* Conditionally render the ProfileNavbar */}
        {!shouldHideNavbar && <ProfileNavbar />}
        <Routes>
          <Route
            path="/"
            element={
              <ProtectedRoute>
                <ProfilePage />
              </ProtectedRoute>
            }
          />
          <Route path="/change-password" element={<ChangePassword />} />
          <Route
            path="/favorites"
            element={
              <ProtectedRoute>
                <FavoritesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/my-listings"
            element={
              <ProtectedRoute>
                <MyListingsPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/messages"
            element={
              <ProtectedRoute>
                <MessagesPage />
              </ProtectedRoute>
            }
          />
          <Route
            path="/create-listing"
            element={
              <ProtectedRoute>
                <CreateListingPage />
              </ProtectedRoute>
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default Profile;
