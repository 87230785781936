import React, { useState } from "react";
import { useUser } from "./UserProvider";
import Modal from "react-modal";
import { sendVerificationEmail, verifyEmail } from "../api/authService";
import PhoneNumberDisplay from "./PhoneNumberDisplay";
import { useTranslation } from "react-i18next";

const ProfilePage = () => {
  const { user } = useUser();
  const { t } = useTranslation();
  const [otpModalOpen, setOtpModalOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpError, setOtpError] = useState("");

  const handleEmailVerification = async () => {
    try {
      await sendVerificationEmail();
      setOtpModalOpen(true);
    } catch (error) {
      setOtpError(t("verificationCodeSendError"));
    }
  };

  const handleOtpSubmit = async () => {
    try {
      await verifyEmail(otp);
      setOtpModalOpen(false);
    } catch (error) {
      setOtpError(t("verificationCodeError"));
      console.error(error);
    }
  };

  if (!user) {
    return <p>...</p>;
  }

  return (
    <div>
      <div className="container mx-auto p-6">
        <div className="gap-6">
          <div className="col-span-1 bg-gray-100 p-4 rounded-lg shadow-md">
            <h2 className="text-xl font-bold mb-4">{t("accountInformation")}</h2>
            <ul className="space-y-3">
              <li>
                <strong>{t("email")}:</strong> {user.email}
              </li>
              <li>
                <strong>{t("phoneNumber")}:</strong>
                <PhoneNumberDisplay value={user.phone_number} />
              </li>
              <li>
                <strong>{t("emailVerification")}:</strong>{" "}
                {user.is_email_verified ? (
                  t("verified")
                ) : (
                  <button
                    onClick={() => handleEmailVerification()}
                    className="text-blue-500"
                  >
                    {t("verify")}
                  </button>
                )}
              </li>
            </ul>

            {user.membership_type === "kurumsal" ? (
              <div className="py-1">
                <ul>
                  <li>
                    <strong>{t("membershipType")}:</strong> {t("corporate")}
                  </li>
                  <li>
                    <strong>{t("companyTitle")}:</strong> {user.company_name}
                  </li>
                </ul>
              </div>
            ) : null}
          </div>
        </div>
      </div>

      <Modal
        isOpen={otpModalOpen}
        onRequestClose={() => setOtpModalOpen(false)}
        className="bg-white p-6 rounded-lg shadow-lg max-w-md mx-auto mt-20"
        overlayClassName="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center"
        ariaHideApp={false}
      >
        <h2 className="text-xl font-bold mb-4">{t("verificationCode")}</h2>
        <p className="mb-4">{t("enterVerificationCode")}</p>
        <input
          type="text"
          value={otp}
          onChange={(e) => setOtp(e.target.value)}
          className="input input-bordered w-full mb-4"
        />
        {otpError && <p className="text-red-500 mb-4">{otpError}</p>}
        <div className="flex justify-end">
          <button
            className="btn btn-secondary mr-2"
            onClick={() => setOtpModalOpen(false)}
          >
            {t("cancel")}
          </button>
          <button className="btn btn-primary" onClick={handleOtpSubmit}>
            {t("verify")}
          </button>
        </div>
      </Modal>
    </div>
  );
};

export default ProfilePage;
