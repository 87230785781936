import api from "./api";

export const getMachine = async (id) => {
  try {
    const response = await api.get(`/machines/${id}/`);
    return response.data;
  } catch (error) {
    console.error("Error fetching machine:", error);
    throw error;
  }
};

export const getMachines = async (params) => {
  try {
    const response = await api.get("/machines/", { params });
    return response.data;
  } catch (error) {
    console.error("Error fetching machines:", error);
    throw error;
  }
};

export const createMachine = async (data) => {
  try {
    const response = await api.post("/machines/", data);
    return response.data;
  } catch (error) {
    console.error("Error creating machine:", error);
    throw error;
  }
};

export const searchListings = async (query) => {
  const response = await api.get(`search/?q=${query}`);
  return response.data;
};
