import React, { useState } from "react";
import { motion } from "framer-motion";
import { sendResetPasswordEmail } from "../api/authService";
import { toast, Toaster } from "react-hot-toast";

import { useTranslation } from "react-i18next";

const ForgotPassword = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await sendResetPasswordEmail(email);
      toast.success("Şifre sıfırlama bağlantısı e-posta adresinize gönderildi");
    } catch (error) {
      toast.error("Bir hata oluştu. Lütfen tekrar deneyin");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-md w-full bg-white rounded-xl shadow-lg p-8"
      >
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-2">
            {t("forgotPasswordTitle")}
          </h2>
          <p className="text-gray-600"></p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1"></label>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t("forgotPasswordEmailInputPlaceholder")}
              className="input input-bordered w-full"
              required
            />
          </div>

          <motion.button
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.99 }}
            type="submit"
            className="btn btn-primary w-full"
          >
            {t("forgotPasswordButton")}
          </motion.button>
        </form>

        <div className="mt-6 text-center">
          <a
            href="/login"
            className="text-sm text-blue-600 hover:text-blue-800 transition-colors"
          >
            {t("forgotPasswordGoBackLogin")}
          </a>
        </div>
      </motion.div>
      <Toaster />
    </div>
  );
};

export default ForgotPassword;
