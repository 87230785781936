import api from "./api";

export const getCategories = async () => {
  try {
    const response = await api.get("/categories/");
    return response.data; // Return the categories data
  } catch (error) {
    console.error("Error fetching categories:", error);
    throw error;
  }
};

export const fetchCategoryAttributes = async (slug) => {
  const response = await api.get(`/categories/${slug}/attributes/`);
  return response.data;
};
