import Footer from "./components/Footer.jsx";
import { CategoryProvider } from "./categoryContext.js";
import Navbar from "./components/NavBar.jsx";
import Home from "./components/Home.jsx";
import Login from "./components/Login.jsx";
import SignUp from "./components/SignUp.jsx";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";

import Profile from "./components/Profile.jsx";

import ProtectedRoute from "./components/ProtectedRoute.jsx";

import { UserProvider } from "./components/UserProvider.jsx";

import ForgotPassword from "./components/ForgotPassword";
import ForgotPasswordRenewal from "./components/ForgotPasswordRenewal";

import HelpCenter from "./components/HelpCenter.jsx";
import Contact from "./components/Contact.jsx";

function App() {
  return (
    <Router>
      <CategoryProvider>
        <UserProvider>
          <div className="flex flex-col min-h-screen">
            <Navbar />
            <main className="shrink">
              <div className="font-sans">
                <Routes>
                  <Route path="/*" element={<Home />} />
                  <Route path="/login" element={<Login />} />
                  <Route path="/signup" element={<SignUp />} />
                  <Route path="/profile/*" element={<Profile />} />
                  <Route
                    path="/forgot-password/"
                    element={<ForgotPassword />}
                  />
                  <Route
                    path="/reset-password/:userId/:token"
                    element={<ForgotPasswordRenewal />}
                  />
                  <Route path="/contact" element={<Contact />} />
                  <Route path="/help-center" element={<HelpCenter />} />
                </Routes>
              </div>
            </main>
            <Footer />

            {/* Footer */}
          </div>
        </UserProvider>
      </CategoryProvider>
    </Router>
  );
}

export default App;
