import React from "react";
import { useTranslation } from "react-i18next";

const AboutSection = () => {
  const { t } = useTranslation();

  return (
    <section className="bg-gray-100 py-16">
      <div className="container mx-auto px-6 md:px-16 lg:px-32">
        <div className="flex flex-col-reverse md:flex-row items-center gap-12">
          {/* Sol Taraf: Metin */}
          <div className="flex-1">
            <h2 className="text-4xl font-bold text-gray-800 leading-tight mb-6 text-2xl md:text-3xl font-extrabold tracking-wide text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600 hover:scale-105 transition-transform duration-300">
              {t("title")}
            </h2>
            <p className="text-lg text-gray-600 mb-4">{t("aboutParagraph1")}</p>
            <p className="text-lg text-gray-600 mb-4">{t("aboutParagraph2")}</p>
          </div>
        </div>
      </div>
    </section>
  );
};

export default AboutSection;
