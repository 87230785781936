import React, { useState, useEffect, useRef } from "react";
import { getCategories } from "../api/categoryService";
import CategoryCard from "./CategoryCard";
import Loading from "./Loading";

import { Link } from "react-router-dom";
import DateComponent from "./DateComponent";

import { useTranslation } from "react-i18next";

const Categories = ({ categoriesRef }) => {
  const { t } = useTranslation();

  const language = localStorage.getItem("i18nextLng") || "tr";

  const scrollToCategory = (index) => {
    if (categoryRefs.current[index]) {
      categoryRefs.current[index].scrollIntoView({ behavior: "smooth" });
    }
  };

  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);

  const categoryRefs = useRef([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        const categoriesData = await getCategories();

        setCategories(categoriesData);
      } catch (err) {
        console.error("Veri yüklenirken hata:", err);
      } finally {
        setLoading(false);
      }
    };

    setLoading(true);
    fetchData();
  }, [language]);

  if (loading) {
    return <Loading />;
  }
  return (
    <div className="py-20" ref={categoriesRef}>
      <div className="max-w-7xl mx-auto px-4">
        <h2 className="text-3xl font-bold text-center mb-4 text-gray-800">
          {t("categoriesTitle")}
        </h2>
        <p className="text-center text-gray-600 mb-16">
          {t("categoriesSubtitle")}
        </p>

        <div className="container mx-auto">
          <div className="flex justify-center gap-4 mb-6">
            {categories?.map((category, index) => (
              <button
                key={index}
                onClick={() => scrollToCategory(index)}
                className="px-4 py-2 hover:bg-blue-200 focus:outline-none"
              >
                {category.name}
              </button>
            ))}
          </div>

          {categories?.map((category, index) => (
            <div
              key={index}
              ref={(el) => (categoryRefs.current[index] = el)}
              className="mb-8"
            >
              <h2 className="text-2xl font-semibold text-gray-700 mb-4">
                {category.name}
              </h2>
              <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-4 gap-6">
                {category.subcategories.map((sub, subIndex) => (
                  <div key={subIndex}>
                    <CategoryCard key={subIndex} sub={sub} />
                  </div>
                ))}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default Categories;
