export const formatPrice = (price, currency = "TRY", locale = "tr-TR") => {
  if (!price) return "Fiyat Sorunuz";

  const currencySymbolsAndLocales = {
    TRY: { symbol: "₺", locale: "tr-TR", style: "decimal" },
    USD: { symbol: "$", locale: "en-US", style: "currency" },
    EUR: { symbol: "€", locale: "de-DE", style: "currency" },
    CNY: { symbol: "¥", locale: "zh-CN", style: "currency" },
  };

  const formatter = new Intl.NumberFormat(
    currencySymbolsAndLocales[currency].locale,
    {
      style: currencySymbolsAndLocales[currency].style,
      currency: currency,
      minimumFractionDigits: 2,
      maximumFractionDigits: 2,
    }
  ).format(price);

  if (currencySymbolsAndLocales[currency].style === "decimal")
    return `${formatter} ${currencySymbolsAndLocales[currency].symbol}`;

  return formatter;
};
