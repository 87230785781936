import React from "react";
import { useTranslation } from "react-i18next";

const HowItWorks = () => {
  const { t } = useTranslation();

  const steps = [
    {
      id: 1,
      title: t("step1Title"),
      description: t("step1Description"),
      icon: "👤",
    },
    {
      id: 2,
      title: t("step2Title"),
      description: t("step2Description"),
      icon: "📋",
    },
    {
      id: 3,
      title: t("step3Title"),
      description: t("step3Description"),
      icon: "🔍",
    },
    {
      id: 4,
      title: t("step4Title"),
      description: t("step4Description"),
      icon: "🤝",
    },
  ];

  return (
    <section className=" py-16">
      <div className="container mx-auto px-6 md:px-16 lg:px-32">
        {/* Başlık */}
        <div className="text-center mb-12">
          <h2 className="text-4xl font-bold text-gray-800 mb-4">
            {t("howItWorksTitle")}
          </h2>
          <p className="text-lg text-gray-600">{t("howItWorksSubtitle")}</p>
        </div>

        {/* Aşamalar */}
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-8">
          {steps.map((step) => (
            <div
              key={step.id}
              className="flex flex-col items-center bg-gray-100 p-6 rounded-lg shadow-lg hover:shadow-xl transition"
            >
              {/* Simge */}
              <div className="text-4xl bg-blue-500 text-white w-16 h-16 flex items-center justify-center rounded-full mb-4">
                {step.icon}
              </div>
              {/* Başlık */}
              <h3 className="text-xl font-bold text-gray-800 mb-2">
                {step.title}
              </h3>
              {/* Açıklama */}
              <p className="text-gray-600 text-center">{step.description}</p>
            </div>
          ))}
        </div>
      </div>
    </section>
  );
};

export default HowItWorks;
