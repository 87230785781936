import React from "react";
import { Link } from "react-router-dom";
import DateComponent from "./DateComponent";

import { formatPrice } from "../utils/formats";

const ProductCard = ({ machine, layout }) => {
  const renderImage = () => {
    if (machine.cover_image) {
      return (
        <img
          src={machine.cover_image}
          alt={machine.name || "Product Image"}
          className="w-20 h-20 object-cover rounded"
        />
      );
    }
    return (
      <div className="w-20 h-20 bg-gray-300 rounded animate-pulse flex items-center justify-center">
        <span className="text-gray-500 text-xs">No Image</span>
      </div>
    );
  };

  if (layout === "mobile") {
    // **Mobil Görünüm (Kart Yapısı)**
    return (
      <div className="bg-base-200 p-4 rounded-lg shadow-md flex space-x-4">
        <Link to={`/product/${machine.id}`} className="flex gap-2 w-full">
          {renderImage()}
          <div className="flex flex-col flex-grow">
            <h3 className="font-semibold text-lg">
              {machine.name || "Unknown Name"}
            </h3>
            <p className="text-sm text-gray-500">
              <DateComponent dateString={machine.updated_at} />
            </p>
            {/* Fiyatı sağ alta almak için `flex` ve `justify-between` kullanıldı */}
            <div className="flex justify-between items-end mt-auto">
              <div></div> {/* Boş div, fiyatı sağa itmek için */}
              <p className="text-sm font-bold text-right">
                {formatPrice(machine.price, machine.currency)}{" "}
              </p>
            </div>
          </div>
        </Link>
      </div>
    );
  }

  // **Masaüstü Görünüm (Tablo Satırı)**
  return (
    <tr className="bg-base-200">
      <td>
        <Link to={`/product/${machine.id}`}>{renderImage()}</Link>
      </td>
      <td>
        <Link to={`/product/${machine.id}`} className="hover:underline">
          {machine.name || "Unknown Name"}
        </Link>
      </td>
      <td>
        <Link to={`/product/${machine.id}`} className="hover:underline">
          <DateComponent dateString={machine.updated_at} />
        </Link>
      </td>
      <td>
        <Link to={`/product/${machine.id}`} className="hover:underline">
          {formatPrice(machine.price, machine.currency)}{" "}
        </Link>
      </td>
    </tr>
  );
};

export default ProductCard;
