import React, { useState, useEffect } from "react";
import ImageUploader from "./ImageUploader";
import CategorySelector from "./CategorySelector";
import { Link } from "react-router-dom";

import { getCategories } from "../api/categoryService";
import { createMachine } from "../api/machineService";

import ProductDetailPreview from "./ProductDetailPreview";
import Loading from "./Loading";

import Confetti from "react-confetti";

import CreateListingStepBar from "./CreateListingStepBar";
import AttributeForm from "./AttributeForm";

import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

import { useTranslation } from "react-i18next";

const CreateListingPage = () => {
  const { t } = useTranslation();

  const [categories, setCategories] = useState([]);
  const [currentStep, setCurrentStep] = useState(1);
  const [data, setData] = useState({
    id: null,
    selectedCategory: null,
    selectedCategoryName: "",
    selectedSubcategory: null,
    selectedSubcategoryName: "",
    title: "",
    description: "",
    address: "",
    price: "",
    images: [],
    attributes: [],
    year: "",
    brand: "",
  });

  const [submitting, setSubmitting] = useState(false);

  useEffect(() => {
    getCategories()
      .then((data) => {
        setCategories(data);
      })
      .catch((error) => {
        console.error("Error fetching categories:", error);
      });
  }, []);

  const handleNextStep = () => {
    console.log("Data:", data);
    if (currentStep < 5) setCurrentStep(currentStep + 1);
  };

  const handlePreviousStep = () => {
    if (currentStep > 1) setCurrentStep(currentStep - 1);
  };

  const navigateToStep = (step) => {
    if (step <= currentStep) setCurrentStep(step);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Data:", data);

    const formData = new FormData();
    formData.append("name", data.title);
    formData.append("address", data.address);
    formData.append("category", data.selectedCategory?.id);
    formData.append("price", data.price);
    formData.append("description", data.description);
    if (data.currency) {
      formData.append("currency", data.currency);
    }
    data.images.forEach((image) => {
      formData.append("images", image.file);
    });

    data.attributes.forEach((attr) => {
      let slug = `attr__${attr.name}`;
      formData.append(slug, attr.value);
    });

    formData.append("year", data.year);
    formData.append("brand", data.brand);

    // Log form data
    for (var pair of formData.entries()) {
      console.log(pair[0] + ", " + pair[1]);
    }

    try {
      setSubmitting(true); // Set loading state

      const response = await createMachine(formData); // Await API call
      console.log("Machine created:", response);
      setData({ ...data, id: response.id });
      setCurrentStep(5);
    } catch (error) {
      console.error("Error creating machine:", error);
    } finally {
      setSubmitting(false);
    }
  };

  return (
    <div className="container mx-auto p-6">
      {/* Step-by-Step Process Navigation */}
      <CreateListingStepBar
        currentStep={currentStep}
        navigateToStep={navigateToStep}
      />

      {submitting ? (
        <Loading />
      ) : (
        <div>
          {currentStep === 1 && (
            <div>
              <CategorySelector
                data={data}
                categories={categories}
                setData={setData}
              />
              <button
                disabled={
                  data?.selectedCategory === null ||
                  data?.selectedCategory?.subcategories?.length > 0
                }
                onClick={handleNextStep}
                className="btn btn-primary mt-6"
              >
                {t("next")}
              </button>
            </div>
          )}

          {currentStep === 2 && (
            <div onSubmit={handleNextStep}>
              <h1 className="text-2xl font-bold mb-4">
                {t("createListingStep2")}
              </h1>
              <div className="mb-4">
                <label className="block text-gray-700">
                  {t("createListingTitle")}:
                </label>
                <input
                  type="text"
                  value={data.title}
                  onChange={(e) => setData({ ...data, title: e.target.value })}
                  className="input input-bordered w-full"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">
                  {t("createListingDescription")}:
                </label>
                <ReactQuill
                  theme="snow"
                  value={data.description}
                  onChange={(e) => setData({ ...data, description: e })}
                />
              </div>
              <div className="mb-4 flex w-full">
                <div className="w-1/2 pr-2">
                  <label className="block text-gray-700">{t("price")}:</label>
                  <input
                    type="number"
                    value={data.price}
                    onChange={(e) =>
                      setData({ ...data, price: e.target.value })
                    }
                    className="input input-bordered w-full"
                    required
                  />
                </div>

                <div className="mb-4">
                  <label className="block text-gray-700">&nbsp;</label>
                  <select
                    value={data.currency}
                    onChange={(e) =>
                      setData({ ...data, currency: e.target.value })
                    }
                    className="select select-bordered w-full"
                  >
                    <option value="TRY">Türk Lirası (₺)</option>
                    <option value="USD">Amerikan Doları ($)</option>
                    <option value="EUR">Euro (€)</option>
                    <option value="CNY">Çin Yuanı (¥)</option>
                  </select>
                </div>
              </div>

              <div className="pr-2">
                <label className="block text-gray-700">{t("address")}:</label>
                <textarea
                  value={data.address}
                  onChange={(e) =>
                    setData({ ...data, address: e.target.value })
                  }
                  className="input input-bordered w-full field-sizing-content h-40 resize-none"
                  required
                />
              </div>
              <div className="mb-4">
                <label className="block text-gray-700">
                  {t("createListingUploadImages")}:
                </label>
                <ImageUploader data={data} setData={setData} />
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={handlePreviousStep}
                  className="btn bg-gray-500 text-white hover:bg-gray-600"
                >
                  Geri
                </button>
                <button
                  type="button"
                  onClick={handleNextStep}
                  className="btn btn-primary"
                >
                  Devam
                </button>
              </div>
            </div>
          )}

          {currentStep === 3 && (
            <div className="text-center">
              <h1 className="text-2xl font-bold mb-4">Özellikler</h1>
              <div className="mb-4">
                <AttributeForm
                  data={data}
                  setData={setData}
                  selectedCategory={data.selectedCategory}
                />
              </div>
              <div className="flex justify-between">
                <button
                  type="button"
                  onClick={handlePreviousStep}
                  className="btn bg-gray-500 text-white hover:bg-gray-600"
                >
                  Geri
                </button>
                <button
                  type="button"
                  onClick={handleNextStep}
                  className="btn btn-primary"
                >
                  Devam
                </button>
              </div>
            </div>
          )}

          {currentStep === 4 && (
            <div>
              <ProductDetailPreview data={data} />

              <div className="flex justify-between mt-6">
                <button
                  type="button"
                  onClick={handlePreviousStep}
                  className="btn bg-gray-500 text-white hover:bg-gray-600"
                >
                  Geri
                </button>
                <button
                  type="button"
                  onClick={handleSubmit}
                  className="btn btn-primary"
                >
                  Yayınla
                </button>
              </div>
            </div>
          )}

          {currentStep === 5 && (
            <div className="text-center">
              <Confetti />
              <h1 className="text-3xl font-bold text-green-500 mb-4">
                Tebrikler!
              </h1>
              <p>İlanınız başarıyla yayınlandı.</p>
              {data.id && (
                <Link
                  to={`/product/${data.id}`}
                  className="btn btn-primary mt-6"
                >
                  İlana Git
                </Link>
              )}
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default CreateListingPage;
