import api from "./api";

export const getFavorites = async () => {
  const response = await api.get("/favorites/");
  return response.data;
};

export const toggleFavorite = async (machineId) => {
  const response = await api.post(`/favorites/${machineId}/`);
  return response;
};

export const getMyMachines = async () => {
  const response = await api.get("/my-machines/");
  return response.data;
};
