import React, { useState, useEffect } from "react";
import { useParams, useSearchParams } from "react-router-dom";
import ProductList from "./ProductList";
import FilterForm from "./FilterForm";
import { getMachines } from "../api/machineService";

const CategoryPage = () => {
  const { categoryName } = useParams();
  const [machines, setMachines] = useState([]);
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(false);
  const [searchParams, setSearchParams] = useSearchParams();
  const [showFilters, setShowFilters] = useState(false); // Filtrelerin görünürlük durumu

  const fetchMachines = async (params) => {
    setLoading(true);
    try {
      const data = await getMachines({ category: categoryName, ...params });
      setMachines(data);
    } catch (err) {
      setError("Failed to fetch machines");
    } finally {
      setLoading(false);
    }
  };

  const parseFiltersFromURL = () => {
    const filters = {};
    for (const [key, value] of searchParams.entries()) {
      if (key.startsWith("attributes")) {
        const attributeKey = key.replace("attributes_", "");
        filters[attributeKey] = value;
      } else {
        filters[key] = value;
      }
    }
    return filters;
  };

  useEffect(() => {
    const filters = parseFiltersFromURL();
    fetchMachines(filters);
  }, [searchParams, categoryName]);

  const handleFilter = (filters) => {
    const newParams = {};
    for (const [key, value] of Object.entries(filters)) {
      if (typeof value === "object") {
        Object.entries(value).forEach(([attrKey, attrValue]) => {
          newParams[`attributes_${attrKey}`] = attrValue;
        });
      } else if (value) {
        newParams[key] = value;
      }
    }
    setSearchParams(newParams);
    setShowFilters(false); // Filtreleme sonrası mobil filtreyi kapat
  };

  return (
    <div className="min-h-screen p-6 bg-gray-50">
      <h1 className="text-4xl font-bold text-gray-800 mb-6">
        {machines.length > 0 ? machines[0].category_name : categoryName}{" "}
      </h1>

      <div className="flex flex-col md:flex-row gap-6">
        {/* Filtreleme Butonu - Mobil için */}
        <button
          className="md:hidden btn btn-primary mb-4"
          onClick={() => setShowFilters(!showFilters)}
        >
          {showFilters ? "Filtreleri Kapat" : "Filtreleri Aç"}
        </button>

        {/* Filtreleme Bölümü */}
        <div
          className={`${
            showFilters ? "block" : "hidden"
          } md:block md:w-1/5 w-full rounded-lg py-5`}
        >
          <FilterForm
            categorySlug={categoryName}
            onFilter={handleFilter}
            initialFilters={parseFiltersFromURL()}
          />
        </div>

        {/* Ürün Listesi */}
        <div className="md:w-3/4 w-full">
          {loading ? (
            <div className="flex justify-center items-center h-20">
              <div className="loader border-t-4 border-blue-500 rounded-full w-8 h-8 animate-spin"></div>
            </div>
          ) : error ? (
            <div className="text-center text-red-500">
              <p>{error}</p>
              <button
                onClick={() => fetchMachines(parseFiltersFromURL())}
                className="btn btn-primary mt-4"
              >
                Retry
              </button>
            </div>
          ) : (
            <ProductList machines={machines} />
          )}
        </div>
      </div>
    </div>
  );
};

export default CategoryPage;
