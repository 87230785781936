import React, { useEffect, useState } from "react";
import { useSearchParams } from "react-router-dom";
import { searchListings } from "../api/machineService";
import ProductList from "./ProductList";
import { useTranslation } from "react-i18next";

const SearchResults = () => {
  const { t } = useTranslation();

  const [searchParams] = useSearchParams();
  const query = searchParams.get("q"); // URL'den "q" parametresini al
  const [results, setResults] = useState([]);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState("");

  useEffect(() => {
    const fetchResults = async () => {
      if (!query) return;

      setLoading(true);
      setError("");

      try {
        const data = await searchListings(query);
        setResults(data);
      } catch (err) {
        setError("Arama sonuçları alınamadı.");
      } finally {
        setLoading(false);
      }
    };

    fetchResults();
  }, [query]);

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-3xl font-bold mb-4">
        {t("searchResultsTitle")} "{query}"
      </h1>
      {loading && <p>{t("loading")}</p>}
      {error && <p className="text-red-500">{error}</p>}
      {!loading && !error && results.length === 0 && (
        <p>
          {t("searchResultsNotFound")} "{query}"
        </p>
      )}
      {!loading && !error && results.length > 0 && (
        <ProductList machines={results} />
      )}
    </div>
  );
};

export default SearchResults;
