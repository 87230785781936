import React, { useState } from "react";

import { useTranslation } from "react-i18next";

const CategorySelector = ({ data, categories, setData }) => {
  const { t } = useTranslation();

  const [selectedCategories, setSelectedCategories] = useState([]); // Seçilen kategoriler

  const handleCategorySelect = (category) => {
    if (category.subcategories && category.subcategories.length > 0) {
      // Yeni alt kategorileri göstermek için kategoriyi seçili hale getir
      setSelectedCategories((prev) => [
        ...prev.slice(0, category.level),
        category,
      ]);
    } else {
      // Alt kategori yoksa seçimi bitir
      setSelectedCategories((prev) => [
        ...prev.slice(0, category.level),
        category,
      ]);
    }

    // Seçilen kategoriyi ana veriye ekle
    setData((prev) => ({
      ...prev,
      selectedCategory: category,
    }));
  };

  const renderCategories = (categories, level, parentId = null) => (
    <div className="" key={parentId}>
      <div className="grid grid-cols-1 gap-4">
        {categories.map((category) => (
          <div
            key={category.id}
            className={`border p-2 rounded-md cursor-pointer text-center ${
              selectedCategories[level]?.id === category.id
                ? "border-green-500 bg-green-100"
                : "border-gray-300"
            }`}
            onClick={() => handleCategorySelect({ ...category, level })}
          >
            {category.name}
          </div>
        ))}
      </div>
    </div>
  );

  return (
    <div>
      <h1 className="text-2xl font-bold mb-4">{t("createListingStep1")}</h1>
      <div className="p-4 border rounded-lg shadow-lg bg-white flex justify-start gap-4">
        {categories && renderCategories(categories, 0)}

        {selectedCategories.map(
          (category, index) =>
            category.subcategories &&
            category.subcategories.length > 0 &&
            renderCategories(category.subcategories, index + 1, category.id)
        )}
      </div>
    </div>
  );
};

export default CategorySelector;
