import React from "react";
import { useTranslation } from "react-i18next";

function CreateListingStepBar({ currentStep, navigateToStep }) {
  const { t } = useTranslation();

  const steps = [
    t("createListingStep1"),
    t("createListingStep2"),
    t("createListingStep3"),
    t("createListingStep4"),
    t("createListingStep5"),
  ];

  return (
    <div className="w-full flex flex-col items-center">
      {/* 🔹 Masaüstü: Tüm adımlar gösterilir */}
      <div className="hidden lg:flex relative items-center justify-between w-full max-w-2xl">
        {/* Arka Plan Çizgisi */}
        <div className="absolute top-1/2 left-0 w-full h-1 bg-gray-300 transform -translate-y-1/2 z-0"></div>

        {/* İlerleme Çizgisi */}
        <div
          className="absolute top-1/2 left-0 h-1 bg-green-500 transform -translate-y-1/2 z-10 transition-all"
          style={{
            width: `${((currentStep - 1) / (steps.length - 1)) * 100}%`,
          }}
        ></div>

        {steps.map((stepName, index) => (
          <button
            key={index}
            onClick={() => navigateToStep(index + 1)}
            className="relative z-20 flex flex-col items-center focus:outline-none"
            disabled={index + 1 > currentStep || currentStep === steps.length}
          >
            {/* Aşama Düğmesi */}
            <div
              className={`w-14 h-14 flex items-center justify-center rounded-full border-2 transition-all ${
                currentStep >= index + 1
                  ? "border-green-500 bg-green-100"
                  : "border-gray-300 bg-gray-100"
              }`}
            >
              <span className="font-bold text-lg">{index + 1}</span>
            </div>
            <span
              className={`mt-2 text-sm ${
                currentStep === index + 1
                  ? "text-gray-700 font-bold"
                  : "text-gray-400"
              }`}
            >
              {stepName}
            </span>
          </button>
        ))}
      </div>

      {/* 🔹 Mobil: Sadece `currentStep` gösterilir */}
      <div className="lg:hidden flex flex-col items-center mt-4">
        <div className="text-lg font-semibold">{steps[currentStep - 1]}</div>
        <div className="w-14 h-14 flex items-center justify-center rounded-full border-2 border-green-500 bg-green-100 mt-2">
          <span className="font-bold text-lg">{currentStep}</span>
        </div>
      </div>
    </div>
  );
}

export default CreateListingStepBar;
