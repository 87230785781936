import { Route, Routes } from "react-router-dom";
import Sidebar from "./Sidebar.jsx";
import CategoryPage from "./CategoryPage.jsx";
import Homepage from "./Homepage.jsx";
import ProductDetailPage from "./ProductDetailPage.jsx";
import Footer from "./Footer.jsx";
import ProtectedRoute from "./ProtectedRoute.jsx";
import ConversationDetail from "./ConversationDetail.jsx";
import SearchResults from "./SearchResults.jsx";

const Home = () => {
  return (
    <div className="flex">
      <div className="flex-1">
        <Routes>
          <Route path="/" element={<Homepage />} />
          <Route
            path="/*"
            element={
              <div className="md:px-52 md:py-10">
                <Routes>
                  <Route
                    path="/category/:categoryName"
                    element={<CategoryPage />}
                  />
                  <Route
                    path="/product/:productId"
                    element={<ProductDetailPage />}
                  />
                  <Route
                    path="/messages/:conversationId"
                    element={
                      <ProtectedRoute>
                        <ConversationDetail />
                      </ProtectedRoute>
                    }
                  />
                  <Route path="/search" element={<SearchResults />} />
                </Routes>
              </div>
            }
          />
        </Routes>
      </div>
    </div>
  );
};

export default Home;
