import { isLoggedIn, decodeToken, getUser } from "../api/authService";
import { useState, useEffect, createContext, useContext } from "react";

const UserContext = createContext();

export const UserProvider = ({ children }) => {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const fetchUser = async () => {
      const decoded_token = decodeToken();
      if (!decoded_token) {
        return;
      }
      const user_id = decoded_token.user_id;
      getUser(user_id).then((r) => {
        setUser(r.data);
        setLoading(false);
      });
    };
    fetchUser();
  }, []);

  return (
    <UserContext.Provider value={{ user, loading }}>
      {children}
    </UserContext.Provider>
  );
};

export const useUser = () => useContext(UserContext);
