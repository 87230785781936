import React from "react";

const formatPhoneNumber = (phoneNumber) => {
  if (!phoneNumber) return "";

  // Sadece rakamları al
  const cleaned = phoneNumber.replace(/\D/g, "");
  console.log(cleaned);
  if (cleaned.startsWith("1") && cleaned.length === 11) {
    // ABD formatı: +1 (XXX) XXX-XXXX
    return `+1 (${cleaned.slice(1, 4)}) ${cleaned.slice(4, 7)}-${cleaned.slice(
      7,
      11
    )}`;
  } else if (cleaned.startsWith("90") && cleaned.length === 12) {
    // Türkiye formatı: +90 (XXX) XXX XX XX
    return `+90 (${cleaned.slice(2, 5)}) ${cleaned.slice(5, 8)} ${cleaned.slice(
      8,
      10
    )} ${cleaned.slice(10, 12)}`;
  } else if (cleaned.startsWith("86") && cleaned.length === 13) {
    // Çin formatı: +86 XX XXXX XXXX
    return `+86 ${cleaned.slice(2, 4)} ${cleaned.slice(4, 8)} ${cleaned.slice(
      8,
      12
    )}`;
  }
  return phoneNumber;
};

const PhoneNumberDisplay = ({ value }) => {
  return (
    <div
      style={{
        width: "100%",
        padding: "10px",
        fontSize: "16px",
        backgroundColor: "#f5f5f5",
        border: "1px solid #ccc",
        borderRadius: "5px",
      }}
    >
      {formatPhoneNumber(value)}
    </div>
  );
};

export default PhoneNumberDisplay;
