import React, { useState, useEffect, useRef } from "react";
import { useParams } from "react-router-dom";
import {
  getConversation,
  sendMessage,
  markMessagesAsRead,
} from "../api/messageService";
import { useUser } from "./UserProvider";
import { PiChecksBold } from "react-icons/pi";

import { useTranslation } from "react-i18next";

const ConversationDetail = () => {
  const { t } = useTranslation();

  const { user } = useUser();

  const { conversationId } = useParams(); // Route parametrelerinden konuşma ID'si alınır
  const [messages, setMessages] = useState([]);
  const [newMessage, setNewMessage] = useState("");
  const [error, setError] = useState(null);

  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(() => {
    const fetchMessages = async () => {
      try {
        const data = await getConversation(conversationId);
        setMessages(data);

        await markMessagesAsRead(conversationId);

        scrollToBottom();
      } catch (err) {
        setError("Failed to fetch messages");
      }
    };

    fetchMessages();
  }, [conversationId]);

  const handleSendMessage = async () => {
    if (!newMessage.trim()) return;

    try {
      const message = await sendMessage(conversationId, {
        content: newMessage,
      });
      setMessages((prevMessages) => [...prevMessages, message]); // Yeni mesajı listeye ekle
      setNewMessage(""); // Mesaj alanını temizle
    } catch (err) {
      setError("Failed to send message");
    }
  };

  return (
    <div className="container mx-auto p-6">
      <h1 className="text-2xl font-bold mb-4">{t("messages")}</h1>
      {error && <p className="text-red-500">{error}</p>}

      {/* Mesajlar */}
      <div className="bg-gray-100 p-4 rounded-lg shadow-md mb-6 overflow-y-auto h-96">
        {messages.map((message) => (
          <div
            key={message?.id}
            className={`mb-4 p-2 rounded-lg max-w-xs ${
              message.sender === user.id
                ? "bg-blue-100 text-right ml-auto"
                : "bg-white text-left"
            }`}
          >
            <p className="text-gray-800">{message.content}</p>
            <span className="text-xs text-gray-500">
              {new Date(message.timestamp).toLocaleString()}
            </span>
            <div
              className={`${
                message.sender === user.id
                  ? "flex justify-start"
                  : "flex justify-end hidden"
              }`}
            >
              <span>
                {
                  <PiChecksBold
                    className={`${
                      message.is_read ? "text-blue-500" : "text-red-500"
                    }`}
                  />
                }
              </span>
            </div>
          </div>
        ))}
        <div ref={messagesEndRef} />
      </div>

      {/* Yeni Mesaj Gönder */}
      <div className="flex items-center gap-4">
        <input
          type="text"
          value={newMessage}
          onChange={(e) => setNewMessage(e.target.value)}
          placeholder={t("messageInputPlaceholder")}
          className="flex-grow border rounded-lg px-4 py-2 focus:outline-none focus:ring-2 focus:ring-blue-500"
        />
        <button
          onClick={handleSendMessage}
          className="bg-blue-500 text-white px-4 py-2 rounded-lg hover:bg-blue-600"
        >
          {t("send")}
        </button>
      </div>
    </div>
  );
};

export default ConversationDetail;
