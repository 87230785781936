import React, { createContext, useContext } from "react";
import { useLocation, useParams } from "react-router-dom";

const CategoryContext = createContext();

export const CategoryProvider = ({ children }) => {
  const location = useLocation();
  const { categoryName: paramCategoryName } = useParams();

  // URL'den manuel olarak kategori adını çözümle
  const categoryFromURL = location.pathname.startsWith("/category/")
    ? location.pathname.split("/category/")[1]
    : null;

  const categoryName = paramCategoryName || categoryFromURL;
  const isCategoryPage = Boolean(categoryName);

  return (
    <CategoryContext.Provider value={{ isCategoryPage, categoryName }}>
      {children}
    </CategoryContext.Provider>
  );
};

export const useCategory = () => useContext(CategoryContext);
