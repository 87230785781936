import api from "./api";

export const getCountries = async () => {
  try {
    const response = await api.get("/countries/");
    return response.data.countries;
  } catch (error) {
    console.error("Error fetching countries:", error);
    throw error;
  }
};

export const getCities = async (country) => {
  try {
    const response = await api.get(`/cities/${country}/`);
    return response.data.cities;
  } catch (error) {
    console.error("Error fetching cities:", error);
    throw error;
  }
};
