import React, { useState, useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import { CgProfile } from "react-icons/cg";
import { useUser } from "./UserProvider";

import { logout } from "../api/authService";

const Navbar = () => {
  const { user } = useUser();

  const BASE_URL = window.location.origin;

  const [showDropdown, setShowDropdown] = useState(false);
  const [searchQuery, setSearchQuery] = useState("");
  const { t } = useTranslation();
  const location = useLocation();
  const navigate = useNavigate();
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false); // Mobil menü durumu

  const toggleDropdown = () => setShowDropdown((prev) => !prev);
  const closeDropdown = () => setShowDropdown(false);
  const toggleMobileMenu = () => setIsMobileMenuOpen(!isMobileMenuOpen); // Mobil menüyü aç/kapa

  useEffect(() => {
    const handleOutsideClick = (e) => {
      if (
        !e.target.closest(".dropdown") &&
        !e.target.closest(".menu") &&
        !e.target.closest(".mobile-menu-button")
      ) {
        closeDropdown();
        setIsMobileMenuOpen(false); // Mobil menüyü de kapat
      }
    };
    document.addEventListener("click", handleOutsideClick);
    return () => document.removeEventListener("click", handleOutsideClick);
  }, []);

  const handleSearch = (e) => {
    e.preventDefault();
    if (searchQuery.trim()) {
      navigate(`/search?q=${encodeURIComponent(searchQuery.trim())}`);
    }
  };

  return (
    <nav className="navbar shadow-lg bg-gradient-to-r from-white via-gray-500 to-black">
      <div className="container mx-auto flex flex-wrap items-center justify-between px-4">
        {" "}
        {/* flex-wrap eklendi */}
        <div className="flex items-center">
          <Link
            to="/"
            className="flex items-center gap-2 text-2xl md:text-3xl font-extrabold tracking-wide text-transparent bg-clip-text bg-gradient-to-r from-blue-400 to-purple-600 hover:scale-105 transition-transform duration-300"
          >
            <img
              src={`${BASE_URL}/media/logo.png`}
              alt=""
              className="h-10 w-auto"
            />
          </Link>
        </div>
        {/* Mobil Menü Butonu */}
        <div className="lg:hidden">
          {" "}
          {/* Sadece küçük ekranlarda göster */}
          <button onClick={toggleMobileMenu} className="mobile-menu-button">
            {" "}
            {/* Butona class eklendi */}
            <svg
              className="w-6 h-6 text-white"
              fill="none"
              stroke="currentColor"
              viewBox="0 0 24 24"
              xmlns="http://www.w3.org/2000/svg"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                strokeWidth="2"
                d={
                  isMobileMenuOpen
                    ? "M6 18L18 6M6 6l12 12"
                    : "M4 6h16M4 12h16M4 18h16"
                } // Icon değişimi
              ></path>
            </svg>
          </button>
        </div>
        <div
          className={`flex items-center gap-4 lg:flex-row ${
            isMobileMenuOpen
              ? "flex-col mt-4 lg:mt-0 w-full lg:w-auto"
              : "hidden lg:flex"
          }`}
        >
          {" "}
          {/* Mobil menü içeriği */}
          <Link to="profile/create-listing" className="w-full lg:w-auto">
            {" "}
            {/* Butonlara genişlik eklendi */}
            <button className="btn bg-blue-600 text-white hover:bg-blue-700 w-full lg:w-auto">
              {t("createListing")}
            </button>
          </Link>
          {user === null ? (
            <Link to="/login" className="w-full lg:w-auto">
              {" "}
              {/* Butona genişlik eklendi */}
              <button className="btn bg-gray-800 text-white hover:bg-gray-900 w-full lg:w-auto">
                {t("login")}/{t("signup")}
              </button>
            </Link>
          ) : (
            <>
              <div className="lg:hidden flex flex-col gap-2 w-full">
                {" "}
                {/* Sadece mobil görünümde göster */}
                <Link to="/profile" className="w-full">
                  <button className="btn bg-gray-800 text-white hover:bg-gray-900 w-full">
                    {t("profile")}
                  </button>
                </Link>
                <Link
                  to="/logout"
                  onClick={() => {
                    logout();
                    toggleMobileMenu(); // Çıkış yapınca mobil menüyü kapat
                  }}
                  className="w-full"
                >
                  <button className="btn bg-red-600 text-white hover:bg-red-700 w-full">
                    {t("logout")}
                  </button>
                </Link>
              </div>
              <div className="dropdown dropdown-end lg:static hidden lg:block">
                {" "}
                {/* Büyük ekranlarda avatar göster */}
                <button
                  aria-haspopup="true"
                  aria-expanded={showDropdown}
                  onClick={toggleDropdown}
                >
                  <div className="rounded-full">
                    <CgProfile className="text-4xl text-white" />
                  </div>
                </button>
                {showDropdown && (
                  <ul className="mt-3 p-2 shadow menu menu-compact dropdown-content bg-base-100 rounded-box w-52 z-50">
                    <li>
                      <Link to="/profile" onClick={closeDropdown}>
                        {t("profile")}
                      </Link>
                    </li>
                    <li>
                      <Link
                        to="/logout"
                        onClick={() => {
                          logout();
                          closeDropdown();
                        }}
                      >
                        {t("logout")}
                      </Link>
                    </li>
                  </ul>
                )}
              </div>
            </>
          )}
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
