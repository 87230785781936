import React from "react";
import ProductCard from "./ProductCard";
import { useTranslation } from "react-i18next";

const ProductList = ({ machines }) => {
  const { t } = useTranslation();

  return (
    <div className="overflow-x-auto">
      <table className="table w-full hidden md:table">
        {/* Table Head */}
        <thead>
          <tr>
            <th></th>
            <th>{t("productTitle")}</th>
            <th>{t("productDate")}</th>
            <th>{t("productPrice")}</th>
          </tr>
        </thead>
        {/* Table Body */}
        <tbody>
          {machines.map((machine) => (
            <ProductCard key={machine.id} machine={machine} />
          ))}
        </tbody>
      </table>

      {/* Mobile View */}
      <div className="md:hidden space-y-4">
        {machines.map((machine) => (
          <ProductCard key={machine.id} machine={machine} layout="mobile" />
        ))}
      </div>
    </div>
  );
};

export default ProductList;
