import React, { useState, useEffect } from "react";
import ProductList from "./ProductList";
import { getMyMachines } from "../api/userService";
import { useTranslation } from "react-i18next";


const MyListings = () => {
  const [myMachines, setMyMachines] = useState([]);
  const { t } = useTranslation();


  useEffect(() => {
    const fetchMyMachines = async () => {
      try {
        const data = await getMyMachines();
        setMyMachines(data);
      } catch (err) {
        console.error("Failed to fetch my machines:", err);
      }
    };

    fetchMyMachines();
  }, []);

  return (
    <div>
      <div className="container mx-auto p-6">
        <h1 className="text-3xl font-bold mb-4">{t("myListings")}</h1>
        <ProductList machines={myMachines} />
      </div>
    </div>
  );
};

export default MyListings;
