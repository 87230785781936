import React, { useState, useEffect, useRef } from "react";
import HeroSection from "./HeroSection";
import Categories from "./Categories";

import SectionSelection from "./SectionSelection";
import HowItWorks from "./HowItWorks";
import AboutSection from "./AboutSection";

const Homepage = () => {
  const categoriesRef = useRef();

  return (
    <div className="min-h-screen bg-[#f8f9fa]">
      {/* Hero Section */}
      <HeroSection />

      {/* HowItWorks */}
      <HowItWorks />
      {/* Section Selection */}
      <SectionSelection categoriesRef={categoriesRef} />

      {/* Categories Section */}
      <Categories categoriesRef={categoriesRef} />

      {/* AboutSection */}
      <AboutSection />
    </div>
  );
};

export default Homepage;
