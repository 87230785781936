import React, { useState, useEffect } from "react";
import ProductList from "./ProductList";
import { getFavorites } from "../api/userService";
import { useTranslation } from "react-i18next";

const FavoritesPage = () => {
  const [favorites, setFavorites] = useState([]);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchFavorites = async () => {
      try {
        const data = await getFavorites();
        setFavorites(data);
      } catch (err) {
        console.error(t("fetchFavoritesError"), err);
      }
    };

    fetchFavorites();
  }, [t]);

  return (
    <div>
      <div className="container mx-auto p-6">
        <h1 className="text-3xl font-bold mb-4">{t("myFavorites")}</h1>
        <ProductList machines={favorites} />
      </div>
    </div>
  );
};

export default FavoritesPage;
