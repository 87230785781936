import React, { useState, useEffect } from "react";
import { getConversations } from "../api/messageService";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

const MessagesPage = () => {
  const [conversations, setConversations] = useState([]);
  const [error, setError] = useState(null);
  const { t } = useTranslation();

  useEffect(() => {
    const fetchConversations = async () => {
      try {
        const data = await getConversations();
        setConversations(data);
      } catch (err) {
        setError(t("fetchConversationsError"));
      }
    };

    fetchConversations();
  }, [t]);

  return (
    <div>
      <div className="container mx-auto p-6">
        <h1 className="text-3xl font-bold mb-4">{t("myMessages")}</h1>
        {error && <p>{error}</p>}

        <div className="grid gap-4">
          {conversations.map((conversation) => (
            <Link
              key={conversation.id}
              to={`/messages/${conversation.id}`}
              className="block"
            >
              <div className="bg-white p-4 shadow-md rounded-lg flex items-start gap-4 hover:shadow-lg transition-shadow">
                {/* Avatar */}
                <div className="w-12 h-12 bg-blue-100 flex items-center justify-center rounded-full text-blue-600 font-bold">
                  {conversation.other_participant[0].toUpperCase()}
                </div>

                {/* Content */}
                <div className="flex-grow">
                  <h2 className="text-lg font-semibold text-gray-800">
                    {conversation.machine.name}
                  </h2>
                  <p className="text-sm text-gray-500 mb-1">
                    {conversation.other_participant}
                  </p>
                  <p className="text-sm text-gray-600 truncate">
                    {conversation.last_message.content}
                  </p>
                  <div className="flex justify-between items-center mt-2 text-sm text-gray-500">
                    <span>
                      {new Date(
                        conversation.last_message.timestamp
                      ).toLocaleString()}
                    </span>
                    <span className="text-blue-600 font-medium">
                      {t("readMore")}
                    </span>
                  </div>
                </div>
              </div>
            </Link>
          ))}
        </div>
      </div>
    </div>
  );
};

export default MessagesPage;
