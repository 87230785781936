import React, { useState } from "react";
import { sendContactMail } from "../api/contactService";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const [submitted, setSubmitted] = useState(false);

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    const response = await sendContactMail(formData);

    if (response.status !== 200) {
      throw new Error("Mesaj gönderilirken hata oluştu.");
    }

    console.log("Gönderilen Mesaj:", formData);
    setSubmitted(true);
  };

  return (
    <div className="max-w-4xl mx-auto p-6">
      <h1 className="text-3xl font-bold text-center text-gray-800 mb-6">
        Bize Ulaşın 📩
      </h1>

      <p className="text-center text-gray-600 mb-8">
        Aşağıdaki formu doldurarak bize mesajınızı gönderebilirsiniz.
      </p>

      <div className="grid md:grid-cols-2 gap-8">
        {/* İletişim Bilgileri */}
        <div className="space-y-4">
          <div className="p-6 border rounded-lg shadow-lg bg-gray-100">
            <h2 className="text-xl font-semibold text-gray-700">📍 Adres</h2>
            <p className="text-gray-600">İstanbul, Türkiye</p>
          </div>

          <div className="p-6 border rounded-lg shadow-lg bg-gray-100">
            <h2 className="text-xl font-semibold text-gray-700">📞 Telefon</h2>
            <p className="text-gray-600">+90 555 555 55 55</p>
          </div>

          <div className="p-6 border rounded-lg shadow-lg bg-gray-100">
            <h2 className="text-xl font-semibold text-gray-700">📧 E-Posta</h2>
            <p className="text-gray-600">teslaecomm@gmail.com</p>
          </div>
        </div>

        {/* İletişim Formu */}
        <div className="p-6 border rounded-lg shadow-lg bg-white">
          {submitted ? (
            <div className="text-green-600 text-center">
              ✅ Mesajınız başarıyla gönderildi!
            </div>
          ) : (
            <form onSubmit={handleSubmit} className="space-y-4">
              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Adınız
                </label>
                <input
                  type="text"
                  name="name"
                  value={formData.name}
                  onChange={handleChange}
                  className="input input-bordered w-full"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  E-Posta
                </label>
                <input
                  type="email"
                  name="email"
                  value={formData.email}
                  onChange={handleChange}
                  className="input input-bordered w-full"
                  required
                />
              </div>

              <div>
                <label className="block text-sm font-medium text-gray-700">
                  Mesajınız
                </label>
                <textarea
                  name="message"
                  value={formData.message}
                  onChange={handleChange}
                  className="textarea textarea-bordered w-full"
                  rows={4}
                  required
                />
              </div>

              <button type="submit" className="btn btn-primary w-full">
                Gönder
              </button>
            </form>
          )}
        </div>
      </div>

      {/* Harita */}
      <div className="mt-10">
        <h2 className="text-2xl font-semibold text-gray-800 text-center mb-4">
          📍 Konumumuz
        </h2>
        <div className="w-full h-80 rounded-lg overflow-hidden">
          <iframe
            className="w-full h-full"
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3015.808315580398!2d29.25762997673156!3d40.898019471366325!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x14cadb6b4ce3f0db%3A0x3c46765278463377!2sVelibaba%2C%20Mimar%20Sinan%20Cd.%20No%3A1%2C%2034896%20Pendik%2F%C4%B0stanbul!5e0!3m2!1str!2str!4v1739386996463!5m2!1str!2str"
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></iframe>
        </div>
      </div>
    </div>
  );
};

export default Contact;
