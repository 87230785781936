import React, { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { getMachine } from "../api/machineService";
import { FiShare2, FiMessageCircle } from "react-icons/fi";
import { MdFavorite, MdFavoriteBorder } from "react-icons/md";
import { toggleFavorite } from "../api/userService";
import { createConversation } from "../api/messageService";
import { formatPrice } from "../utils/formats";
import PhoneNumberDisplay from "./PhoneNumberDisplay";
import { useTranslation } from "react-i18next";

const ProductDetailPage = () => {
  const { t } = useTranslation();

  const { productId } = useParams();
  const [machine, setMachine] = useState(null);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);
  const [loading, setLoading] = useState(true);

  const navigate = useNavigate();

  const handleSendMessage = () => {
    createConversation(machine.id).then((conversation) => {
      console.log("Conversation created:", conversation);
      navigate(`/messages/${conversation.id}`);
    });
  };

  useEffect(() => {
    const fetchMachine = async () => {
      try {
        const data = await getMachine(productId);
        setMachine(data);
      } catch (err) {
        console.error("Failed to fetch machine:", err);
      } finally {
        setLoading(false);
      }
    };

    fetchMachine();
  }, [productId]);

  if (loading) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-gray-900"></div>
      </div>
    );
  }

  if (!machine) {
    return (
      <div className="min-h-screen flex items-center justify-center">
        <div className="text-gray-600 text-xl">{t("productNotFound")}</div>
      </div>
    );
  }

  return (
    <div className="min-h-screen bg-gray-50">
      {/* Üst Başlık */}
      <div className="bg-white border-b">
        <div className="max-w-7xl mx-auto px-4 py-6">
          <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4">
            <div>
              <h1 className="text-2xl md:text-3xl font-bold text-gray-900">
                {machine.name}
              </h1>
              <p className="text-gray-500 mt-1">
                {t("listingNumber")} {machine.id}
              </p>
            </div>
            <div className="flex items-center gap-4">
              {/* <button className="flex items-center gap-2 text-gray-600 hover:text-gray-900">
                <FiShare2 className="w-5 h-5" />
                <span className="hidden md:inline">{t("share")}</span>
              </button> */}
              {!machine.is_owner ? (
                <button
                  className="flex items-center gap-2 text-gray-600 hover:text-red-500"
                  onClick={async () => {
                    await toggleFavorite(productId);
                    setMachine((prev) => ({
                      ...prev,
                      is_favorited: !prev.is_favorited,
                    }));
                  }}
                >
                  {machine.is_favorited ? (
                    <MdFavorite className="w-5 h-5 text-red-500" />
                  ) : (
                    <MdFavoriteBorder className="w-5 h-5" />
                  )}

                  {machine.is_favorited ? (
                    <span className="hidden md:inline">{t("unFavorite")}</span>
                  ) : (
                    <span className="hidden md:inline">{t("favorite")}</span>
                  )}
                </button>
              ) : null}
            </div>
          </div>
        </div>
      </div>

      <div className="max-w-7xl mx-auto px-4 py-8">
        <div className="grid grid-cols-1 lg:grid-cols-12 gap-8">
          {/* Sol: Fotoğraflar */}
          <div className="lg:col-span-8">
            <div className="bg-white rounded-lg shadow-sm p-4">
              {/* Ana Fotoğraf */}
              <div className="relative mb-4">
                <img
                  src={machine.images?.[selectedImageIndex]?.image}
                  alt={machine.name}
                  className="w-full h-[500px] object-contain bg-gray-100 rounded-lg"
                />

                <div className="absolute top-4 right-4 bg-white p-2 rounded-full shadow-md">
                  <span className="text-gray-600">
                    {selectedImageIndex + 1}
                  </span>

                  <span className="text-gray-400"> / </span>

                  <span className="text-gray-600">
                    {machine.images?.length}
                  </span>
                </div>

                <div>
                  <button
                    className="absolute top-60 left-4 bg-white p-2 rounded-full shadow-md"
                    onClick={() =>
                      setSelectedImageIndex(
                        (prev) =>
                          (prev - 1 + machine.images.length) %
                          machine.images.length
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M15 19l-7-7 7-7"
                      />
                    </svg>
                  </button>

                  <button
                    className="absolute top-60 right-4 bg-white p-2 rounded-full shadow-md"
                    onClick={() =>
                      setSelectedImageIndex(
                        (prev) => (prev + 1) % machine.images.length
                      )
                    }
                  >
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      className="h-6 w-6 text-gray-600"
                      fill="none"
                      viewBox="0 0 24 24"
                      stroke="currentColor"
                    >
                      <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        strokeWidth="2"
                        d="M9 5l7 7-7 7"
                      />
                    </svg>
                  </button>
                </div>
              </div>

              {/* Thumbnail'lar */}
              <div className="grid grid-cols-4 gap-4">
                {machine.images
                  ?.slice(
                    selectedImageIndex - 3 < 0 ? 0 : selectedImageIndex - 3,
                    selectedImageIndex + 1 < 4
                      ? 4
                      : selectedImageIndex + 1 > machine.images.length
                      ? machine.images.length
                      : selectedImageIndex + 1
                  )
                  .map((img, index) => {
                    const actualIndex =
                      selectedImageIndex - 3 < 0
                        ? index
                        : selectedImageIndex - 3 + index;

                    return (
                      <div
                        key={actualIndex}
                        className={`flex-1 aspect-[4/3] relative rounded-lg overflow-hidden cursor-pointer
            ${
              selectedImageIndex === actualIndex
                ? "ring-2 ring-blue-500"
                : "hover:ring-2 hover:ring-gray-300"
            }`}
                        onClick={() => setSelectedImageIndex(actualIndex)}
                      >
                        <img
                          src={img.image}
                          alt={`${machine.name} ${actualIndex + 1}`}
                          className="w-full h-full object-cover"
                        />
                      </div>
                    );
                  })}
              </div>
            </div>
          </div>

          {/* Sağ: Detaylar ve İletişim */}
          <div className="lg:col-span-4 space-y-6 relative">
            {/* Fiyat ve İletişim Kartı */}
            <div className="bg-white rounded-lg shadow-sm p-6">
              <div className="space-y-6">
                <div>
                  <span className="text-sm text-gray-500">{t("price")}</span>
                  <div className="text-2xl font-bold text-gray-900">
                    {formatPrice(machine.price, machine.currency)}
                  </div>
                </div>

                <div className="border-t pt-6">
                  <h3 className="font-semibold text-lg mb-4">
                    {t("sellerInfo")}
                  </h3>
                  <div className="space-y-4">
                    <div>
                      <div className="flex items-center gap-2">
                        <p className="text-gray-600 font-bold">
                          {machine.seller_name === undefined ? (
                            <Link to="/login">
                              Görmek için lütfen giriş yapınız
                            </Link>
                          ) : (
                            machine.seller_name
                          )}
                        </p>
                      </div>

                      <div className="flex items-center gap-2 text-gray-600">
                        <a
                          href={`tel:+${machine.seller_phone}`}
                          className="text-blue-600 hover:underline"
                        >
                          {/* {machine.seller_phone ? <>+</> : null} */}
                          {machine.seller_name !== undefined ? (
                            <PhoneNumberDisplay value={machine.seller_phone} />
                          ) : null}
                        </a>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            {/* Sabit Mesaj Butonu */}
            {!machine.is_owner & !(machine.seller_name === undefined) ? (
              <div className="sticky bottom-6 z-10 bg-white p-4 shadow-lg rounded-lg">
                <button
                  onClick={handleSendMessage}
                  className="flex items-center justify-center gap-2 w-full bg-blue-600 text-white py-3 px-4 rounded-md hover:bg-blue-700"
                >
                  <FiMessageCircle className="w-5 h-5" />
                  <span>{t("sendMessage")}</span>
                </button>
              </div>
            ) : null}

            <div className="bg-white rounded-lg shadow-sm p-6">
              <div className="space-y-6">
                <h3 className="font-semibold text-lg mb-4">Adres</h3>
                <div className="space-y-4">
                  <div>
                    <p className="text-gray-600">{machine.address}</p>
                  </div>
                </div>
              </div>
            </div>

            {/* Teknik Özellikler */}
            <div className="bg-white rounded-lg shadow-sm p-6">
              <h2 className="text-xl font-semibold mb-4">
                {t("technicalDetails")}
              </h2>
              <div className="space-y-3">
                {machine.attribute_values?.map((attr) => (
                  <div
                    key={attr.attribute_display_name}
                    className="flex justify-between py-2 border-b"
                  >
                    <span className="text-gray-600">
                      {attr.attribute_display_name}
                    </span>
                    <span className="font-medium">{attr.value}</span>
                  </div>
                ))}
              </div>
            </div>

            {/* Açıklama */}
            {machine.description && (
              <div className="bg-white rounded-lg shadow-sm p-6">
                <h2 className="text-xl font-semibold mb-4">
                  {t("description")}
                </h2>
                <p className="text-gray-600 whitespace-pre-line">
                  <div
                    dangerouslySetInnerHTML={{ __html: machine.description }}
                  />
                </p>
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ProductDetailPage;
