import api from "./api";

export const createConversation = async (machineId) => {
  const response = await api.post("/conversations/create/", {
    machine: machineId,
  });
  return response.data;
};

export const getConversations = async () => {
  const response = await api.get("/conversations/");
  return response.data;
};

export const getConversation = async (id) => {
  const response = await api.get(`/conversations/${id}/messages/`);
  return response.data;
};

export const sendMessage = async (id, content) => {
  const response = await api.post(`/conversations/${id}/messages/send/`, {
    content: content.content,
  });
  return response.data;
};

export const markMessagesAsRead = async (conversationId) => {
  const response = await api.patch(
    `/conversations/${conversationId}/messages/mark-as-read/`
  );
  return response.data;
};
