import React, { useState, useEffect } from "react";
import { fetchCategoryAttributes } from "../api/categoryService";
import { FiInfo } from "react-icons/fi";

function AttributeForm({ data, setData, selectedCategory }) {
  const [attributes, setAttributes] = useState([]);

  useEffect(() => {
    if (selectedCategory) {
      fetchCategoryAttributes(selectedCategory.slug)
        .then((data) => {
          setAttributes(data);
        })
        .catch((error) => {
          console.error("Error fetching attributes:", error);
        });
    }
  }, [selectedCategory]);

  const handleAttributeChange = (e, attribute) => {
    setData((prev) => ({
      ...prev,
      attributes: [
        ...prev.attributes.filter((attr) => attr.id !== attribute.id),
        {
          id: attribute.id,
          name: attribute.name,
          display_name: attribute.display_name,
          value: e.target.value,
        },
      ],
    }));
  };

  return (
    <div className="p-6 bg-white shadow-md rounded-lg">
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 gap-6">
        {attributes.map((attribute) => (
          <div key={attribute.id} className="flex flex-col">
            <label
              htmlFor={attribute.slug}
              className="text-sm font-medium text-gray-700 flex items-center"
            >
              {attribute.display_name}
              {attribute.description && (
                <span
                  className="ml-2 text-gray-500"
                  title={attribute.description}
                >
                  <FiInfo />
                </span>
              )}
            </label>

            {attribute.filter_type === "range" ||
            attribute.filter_type === "date" ? (
              <input
                id={attribute.slug}
                type={attribute.data_type}
                value={
                  data.attributes.find((attr) => attr.id === attribute.id)
                    ?.value || ""
                }
                onChange={(e) => handleAttributeChange(e, attribute)}
                className="mt-2 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
              />
            ) : attribute.data_type === "boolean" ? (
              <div className="flex items-center mt-2">
                <input
                  id={attribute.slug}
                  type="checkbox"
                  checked={data[attribute.slug] || false}
                  onChange={(e) => handleAttributeChange(e, attribute)}
                  className="h-5 w-5 text-blue-600 border-gray-300 rounded focus:ring-blue-500"
                />
                <label htmlFor={attribute.slug} className="ml-2 text-gray-700">
                  {attribute.display_name}
                </label>
              </div>
            ) : attribute.filter_type === "choice" ? (
              <select
                id={attribute.slug}
                value={
                  data.attributes.find((attr) => attr.id === attribute.id)
                    ?.value || ""
                }
                onChange={(e) => handleAttributeChange(e, attribute)}
                className="mt-2 p-2 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:outline-none"
              >
                <option value="">Seçin</option>
                {attribute.choices.map((choice, index) => (
                  <option key={index} value={choice}>
                    {choice}
                  </option>
                ))}
              </select>
            ) : null}
          </div>
        ))}
      </div>
    </div>
  );
}

export default AttributeForm;
