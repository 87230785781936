import React, { useState } from "react";
import { useTranslation } from "react-i18next";

import { login } from "../api/authService";

const Login = () => {
  const { t } = useTranslation();

  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [error, setError] = useState(null);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await login(email, password);
      window.location.href = "/"; // Redirect to home on successful login
    } catch (error) {
      setError(error);
    }
  };

  return (
    <div className="flex justify-center items-center h-screen bg-base-200">
      <div className="card w-96 bg-base-100 shadow-xl">
        <div className="card-body">
          <h2 className="card-title">{t("loginTitle")}</h2>
          <form>
            <input
              type="email"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
              placeholder={t("emailPlaceholder")}
              className="input input-bordered w-full mb-4"
            />
            <input
              type="password"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder={t("passwordPlaceholder")}
              className="input input-bordered w-full mb-4"
            />
            <button onClick={handleSubmit} className="btn btn-primary w-full">
              {t("loginButton")}
            </button>
          </form>
          <div className="text-center mt-4">
            <a href="/forgot-password" className="link link-primary">
              {t("forgotPassword")}
            </a>
            <div className="mt-2">
              <a href="/signup" className="link link-primary">
                {t("signup")}
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
