import React, { useState, useEffect } from "react";
import { motion } from "framer-motion";
import { useSearchParams, useNavigate, useParams } from "react-router-dom";
import { verifyResetCode } from "../api/authService";
import { toast, Toaster } from "react-hot-toast";

const ForgotPasswordRenewal = () => {
  const { userId, token } = useParams();
  const [formData, setFormData] = useState({
    newPassword: "",
    confirmPassword: "",
  });

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (formData.newPassword !== formData.confirmPassword) {
      toast.error("Şifreler eşleşmiyor");
      return;
    }

    try {
      await verifyResetCode(userId, token, formData.newPassword);
      toast.success("Şifreniz başarıyla değiştirildi");
    } catch (error) {
      console.error(error);
      toast.error("Bir hata oluştu. Lütfen tekrar deneyin");
    }
  };

  return (
    <div className="min-h-screen flex items-center justify-center bg-gray-100 px-4">
      <motion.div
        initial={{ opacity: 0, y: 20 }}
        animate={{ opacity: 1, y: 0 }}
        className="max-w-md w-full bg-white rounded-xl shadow-lg p-8"
      >
        <div className="text-center mb-8">
          <h2 className="text-3xl font-bold text-gray-900 mb-2">
            Şifre Yenileme
          </h2>
          <p className="text-gray-600">
            E-postanıza gönderilen kodu girin ve yeni şifrenizi belirleyin
          </p>
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Yeni Şifre
            </label>
            <input
              type="password"
              name="newPassword"
              value={formData.newPassword}
              onChange={(e) =>
                setFormData({ ...formData, newPassword: e.target.value })
              }
              placeholder="Yeni şifrenizi girin"
              className="input input-bordered w-full"
              required
              minLength={6}
            />
          </div>

          <div>
            <label className="block text-sm font-medium text-gray-700 mb-1">
              Şifre Tekrar
            </label>
            <input
              type="password"
              name="confirmPassword"
              value={formData.confirmPassword}
              onChange={(e) =>
                setFormData({ ...formData, confirmPassword: e.target.value })
              }
              placeholder="Yeni şifrenizi tekrar girin"
              className="input input-bordered w-full"
              required
              minLength={6}
            />
          </div>

          <motion.button
            whileHover={{ scale: 1.01 }}
            whileTap={{ scale: 0.99 }}
            type="submit"
            className="btn btn-primary w-full"
          >
            Şifreyi Yenile
          </motion.button>
        </form>

        <div className="mt-6 text-center">
          <a
            href="/login"
            className="text-sm text-blue-600 hover:text-blue-800 transition-colors"
          >
            Giriş sayfasına dön
          </a>
        </div>
      </motion.div>
      <Toaster position="top-right" />
    </div>
  );
};

export default ForgotPasswordRenewal;
