import i18n from "i18next";
import { initReactI18next } from "react-i18next";
import LanguageDetector from "i18next-browser-languagedetector";

const resources = {
  tr: {
    translation: {
      title: "vinçburada",

      // Hero Section
      heroTitle: "İş Makinesi mi Arıyorsunuz?",
      heroSubtitle: "Aradığınız her şey burada!",
      heroSearchPlaceholder: "Ne arıyorsunuz? (örn: vinç, forklift...)",
      search: "Ara",

      // How It Works Section
      howItWorksTitle: "Nasıl Çalışır?",
      howItWorksSubtitle:
        "İş makinelerini kiralamak veya listelemek hiç bu kadar kolay olmamıştı. Aşağıdaki adımları takip edin!",
      step1Title: "Üye Ol",
      step1Description:
        "Platformumuza üye olun ve iş makinelerine erişim için ilk adımı atın.",
      step2Title: "İlan Ver",
      step2Description:
        "İş makinelerinizi kolayca listeleyin ve doğru kişilere ulaşın.",
      step3Title: "Ara ve Bul",
      step3Description:
        "İhtiyacınız olan iş makinelerini arayın ve detaylı bilgilere ulaşın.",
      step4Title: "Güvenle Anlaş",
      step4Description:
        "Platformumuzun sağladığı güvenli altyapı ile anlaşmalarınızı tamamlayın.",

      // Section Selection Section
      sectionSelectionTitle1: "İş Makinesi Sahibi Misin?",
      sectionSelectionButton1: "İlan Ver",
      sectionSelectionTitle2:
        "İş Makinesi Satın Almak/Kiralamak mı İstiyorsun?",
      sectionSelectionButton2: "İlanları İncele",

      // Categories Section
      categoriesTitle: "Popüler Kategoriler",
      categoriesSubtitle: "En çok aranan kategoriler arasından seçim yapın",
      categoriesButton: "Tüm Kategorileri Gör",

      // About Section
      aboutParagraph1:
        "Platformumuz, iş makineleri sahipleri ve kiralamak isteyenler arasında bir köprü kurarak güvenli, hızlı ve kolay bir deneyim sunmak için oluşturulmuştur. Amacımız, sektörün ihtiyaçlarını en iyi şekilde karşılayarak kullanıcılarımızın memnuniyetini en üst düzeye çıkarmaktır.",
      aboutParagraph2:
        "Güvenilirlik, yenilikçilik ve sürdürülebilirlik temel değerlerimiz arasında yer alıyor. Teknolojiyle güçlendirilmiş çözümlerimizle sektörde fark yaratmayı hedefliyoruz.",

      // Filter Form
      filterFormTitle: "Filtreleme",
      filterFormButton: "Filtrele",
      filterSelectPlaceholder: "Seç",
      filterBooleanOptionTrue: "Evet",
      filterBooleanOptionFalse: "Hayır",

      // Product List Headers
      productTitle: "Başlık",
      productDate: "İlan Tarihi",
      productPrice: "Fiyat",

      // Product Detail Page
      share: "Paylaş",
      favorite: "Favorilere Ekle",
      unFavorite: "Favorilerden Çıkar",
      price: "Fiyat",
      sellerInfo: "Satıcı Bilgileri",
      sendMessage: "Mesaj Gönder",
      technicalDetails: "Teknik Özellikler",
      description: "Açıklama",
      listingNumber: "İlan No: ",
      productNotFound: "Ürün bulunamadı..",
      noImage: "Fotoğraf yok",

      // Conversation Detail
      messages: "Mesajlar",
      messageInputPlaceholder: "Mesajınızı girin...",
      send: "Gönder",

      // Search Results
      searchResultsTitle: "Arama Sonuçları",
      loading: "Yükleniyor...",
      searchResultsNotFound: "Arama sonuç bulunamadı.",

      // Create Listing Page
      createListingStep1: "Kategori Seçimi",
      createListingStep2: "İlan Detayları",
      createListingStep3: "Özellikler",
      createListingStep4: "Önizleme",
      createListingStep5: "Tebrikler",
      next: "Devam",
      createListingTitle: "İlan Başlığı",
      createListingDescription: "Açıklama",
      createListingUploadImages: "Resim Yükle",
      createListingUploadImagesText: "En fazla 10 fotoğraf yükleyebilirsiniz.",
      address: "Adres",

      //Profile page
      accountDetails: "Hesap Detayları",
      changePassword: "Şifre Değiştir",
      myListings: "İlanlarım",
      myFavorites: "Favorilerim",
      myMessages: "Mesajlarım",

      // Forgot Password Page
      forgotPasswordTitle: "Şifremi Unuttum",
      forgotPasswordEmailInputPlaceholder: "E-posta adresinizi girin",
      forgotPasswordButton: "Şifre Sıfırlama Bağlantısı Gönder",
      forgotPasswordGoBackLogin: "Giriş sayfasına dön",

      // Login Page
      login: "Giriş Yap",
      signup: "Kayıt Ol",
      profile: "Profil",
      loginTitle: "Hesabınıza Giriş Yapın",
      emailPlaceholder: "E-posta adresinizi girin",
      passwordPlaceholder: "Şifrenizi girin",
      loginButton: "Giriş Yap",
      forgotPassword: "Şifrenizi mi unuttunuz?",
      signupTitle: "Bir Hesap Oluşturun",
      firstNamePlaceholder: "Adınızı girin",
      lastNamePlaceholder: "Soyadınızı girin",
      signupButton: "Kayıt Ol",
      alreadyHaveAccount: "Zaten bir hesabınız var mı?",
      newsletterTitle: "Güncel Kalın",
      newsletterText: "Bültenimize abone olun",
      subscribe: "Abone Ol",
      supportTitle: "Destek",
      contact: "İletişim",
      helpCenter: "Yardım Merkezi",
      socialTitle: "Bizi Takip Edin",
      languageTitle: "Dil",
      categories: "Kategoriler",
      createListing: "Ücretsiz İlan Ver",
      logout: "Çıkış Yap",
      accountDetails: "Hesap Detay",
      changePassword: "Şifre Değiştir",
      myListings: "İlanlarım",
      myFavorites: "Favori İlanlarım",
      myMessages: "Mesajlarım",
      accountInformation: "Hesap Bilgilerim",
      email: "E-Posta",
      phoneNumber: "Cep Telefonu",
      emailVerification: "Email Doğrulama",
      verified: "Doğrulandı",
      verify: "Doğrula",
      membershipType: "Üyelik Türü",
      corporate: "Kurumsal",
      companyTitle: "Firma Ünvanı",
      verificationCode: "Doğrulama Kodu",
      enterVerificationCode: "Lütfen e-posta adresinize gönderilen kodu girin:",
      cancel: "İptal",
      verificationCodeSendError: "Doğrulama kodu gönderilemedi.",
      verificationCodeError: "Doğrulama kodu hatalı.",
      currentPassword: "Mevcut Şifre",
      newPassword: "Yeni Şifre",
      confirmNewPassword: "Yeni Şifreyi Onayla",
      passwordsDoNotMatch: "Yeni şifreler eşleşmiyor",
      passwordChangeSuccess: "Şifreniz başarıyla değiştirildi",
      passwordChangeError: "Şifre değiştirilemedi",
      fetchFavoritesError: "Favoriler yüklenirken hata oluştu",
      fetchConversationsError: "Mesajlar yüklenirken hata oluştu",
      readMore: "Devamını oku",
    },
  },
  en: {
    translation: {
      title: "vinçburada",

      // Hero Section
      heroTitle: "Looking for a Machine?",
      heroSubtitle: "Everything you need is here!",
      heroSearchPlaceholder:
        "What are you looking for? (e.g. crane, forklift...)",
      search: "Search",

      // How It Works Section
      howItWorksTitle: "How It Works?",
      howItWorksSubtitle:
        "Renting or listing machines has never been easier. Follow the steps below!",
      step1Title: "Sign Up",
      step1Description:
        "Sign up to our platform and take the first step to access machines.",
      step2Title: "List Your Machine",
      step2Description: "Easily list your machines and reach the right people.",
      step3Title: "Search and Find",
      step3Description:
        "Search for the machines you need and access detailed information.",
      step4Title: "Make a Deal",
      step4Description:
        "Complete your deals with the secure infrastructure provided by our platform.",
      // Section Selection Section
      sectionSelectionTitle1: "Do You Own a Machine?",
      sectionSelectionButton1: "Create Listing",
      sectionSelectionTitle2: "Want to Buy/Rent a Machine?",
      sectionSelectionButton2: "Browse Listings",

      // Categories Section
      categoriesTitle: "Popular Categories",
      categoriesSubtitle: "Choose from the most searched categories",
      categoriesButton: "View All Categories",

      // About Section
      aboutParagraph1:
        "Our platform is created to provide a secure, fast, and easy experience by bridging the gap between machine owners and those who want to rent. Our goal is to meet the needs of the industry in the best way possible and maximize the satisfaction of our users.",
      aboutParagraph2:
        "Reliability, innovation, and sustainability are among our core values. We aim to make a difference in the industry with our technology-empowered solutions.",

      // Filter Form
      filterFormTitle: "Filter",
      filterFormButton: "Filter",
      filterSelectPlaceholder: "Select",
      filterBooleanOptionTrue: "Yes",
      filterBooleanOptionFalse: "No",

      // Product List Headers
      productTitle: "Title",
      productDate: "Date",
      productPrice: "Price",

      // Product Detail Page
      share: "Share",
      favorite: "Add to Favorites",
      unFavorite: "Remove from Favorites",
      price: "Price",
      sellerInfo: "Seller Information",
      sendMessage: "Send Message",
      technicalDetails: "Technical Details",
      description: "Description",
      listingNumber: "Listing Number: ",
      productNotFound: "Product not found..",

      // Conversation Detail
      messages: "Messages",
      messageInputPlaceholder: "Type your message...",
      send: "Send",

      // Search Results
      searchResultsTitle: "Search Results",
      loading: "Loading...",
      searchResultsNotFound: "No search results found.",

      // Create Listing Page
      createListingStep1: "Category Selection",
      createListingStep2: "Listing Details",
      createListingStep3: "Attributes",
      createListingStep4: "Preview",
      createListingStep5: "Congratulations",
      next: "Continue",
      createListingTitle: "Listing Title",
      createListingDescription: "Description",
      createListingUploadImages: "Upload Images",
      createListingUploadImagesText: "You can upload up to 10 photos.",
      address: "Address",

      // Profile Page
      accountDetails: "Account Details",
      changePassword: "Change Password",
      myListings: "My Listings",
      myFavorites: "Favorites",
      myMessages: "Messages",

      // Forgot Password Page
      forgotPasswordTitle: "Forgot Password",
      forgotPasswordEmailInputPlaceholder: "Enter your email adress",
      forgotPasswordButton: "Send password renewal link",
      forgotPasswordGoBackLogin: "Go back to login page",

      // Login Page
      login: "Login",
      signup: "Sign Up",
      profile: "Profile",
      loginTitle: "Log in to Your Account",
      emailPlaceholder: "Enter your email",
      passwordPlaceholder: "Enter your password",
      loginButton: "Log In",
      forgotPassword: "Forgot Password?",
      signupTitle: "Create an Account",
      firstNamePlaceholder: "Enter your first name",
      lastNamePlaceholder: "Enter your last name",
      signupButton: "Sign Up",
      alreadyHaveAccount: "Already have an account?",
      newsletterTitle: "Stay Updated",
      newsletterText: "Subscribe to our newsletter",
      subscribe: "Subscribe",
      supportTitle: "Support",
      contact: "Contact",
      helpCenter: "Help Center",
      socialTitle: "Follow Us",
      languageTitle: "Language",
      categories: "Categories",
      createListing: "Create Listing",
      logout: "Logout",
      accountInformation: "My Account Information",
      email: "Email",
      phoneNumber: "Phone Number",
      emailVerification: "Email Verification",
      verified: "Verified",
      verify: "Verify",
      membershipType: "Membership Type",
      corporate: "Corporate",
      companyTitle: "Company Title",
      verificationCode: "Verification Code",
      enterVerificationCode:
        "Please enter the code sent to your email address:",
      cancel: "Cancel",
      verificationCodeSendError: "Verification code could not be sent.",
      verificationCodeError: "Invalid verification code.",
      currentPassword: "Current Password",
      newPassword: "New Password",
      confirmNewPassword: "Confirm New Password",
      passwordsDoNotMatch: "New passwords do not match",
      passwordChangeSuccess: "Your password has been changed successfully",
      passwordChangeError: "Password could not be changed",
      fetchFavoritesError: "Failed to fetch favorites",
      fetchConversationsError: "Failed to fetch messages",
      readMore: "Read more",
    },
  },
  zh: {
    translation: {
      title: "起重机在此",

      // Hero Section
      heroTitle: "正在寻找工程机械？",
      heroSubtitle: "您需要的一切都在这里！",
      heroSearchPlaceholder: "您在寻找什么？（例如：起重机、叉车...）",
      search: "搜索",

      // How It Works Section
      howItWorksTitle: "如何使用？",
      howItWorksSubtitle: "租赁或列出机械从未如此简单。请按照以下步骤操作！",
      step1Title: "注册",
      step1Description: "注册我们的平台，迈出使用机械的第一步。",
      step2Title: "发布广告",
      step2Description: "轻松列出您的机械，接触合适的客户。",
      step3Title: "搜索查找",
      step3Description: "搜索您需要的机械并获取详细信息。",
      step4Title: "安全交易",
      step4Description: "通过我们平台提供的安全基础设施完成交易。",

      // Section Selection Section
      sectionSelectionTitle1: "您是机械所有者吗？",
      sectionSelectionButton1: "发布广告",
      sectionSelectionTitle2: "想要购买/租赁机械吗？",
      sectionSelectionButton2: "浏览广告",

      // Categories Section
      categoriesTitle: "热门类别",
      categoriesSubtitle: "从最受欢迎的类别中选择",
      categoriesButton: "查看所有类别",

      // About Section
      aboutParagraph1:
        "我们的平台旨在为机械所有者和租赁者之间搭建桥梁，提供安全、快速、便捷的体验。我们的目标是以最佳方式满足行业需求，最大限度地提高用户满意度。",
      aboutParagraph2:
        "可靠性、创新性和可持续性是我们的核心价值观。我们致力于通过技术支持的解决方案在行业中创造差异。",

      // Filter Form
      filterFormTitle: "筛选",
      filterFormButton: "筛选",
      filterSelectPlaceholder: "选择",
      filterBooleanOptionTrue: "是",
      filterBooleanOptionFalse: "否",

      // Product List Headers
      productTitle: "标题",
      productDate: "日期",
      productPrice: "价格",

      // Product Detail Page
      share: "分享",
      favorite: "添加到收藏",
      unFavorite: "取消收藏",
      price: "价格",
      sellerInfo: "卖家信息",
      sendMessage: "发送消息",
      technicalDetails: "技术规格",
      description: "描述",
      listingNumber: "广告编号：",
      productNotFound: "未找到产品..",
      noImage: "暂无图片",

      // Conversation Detail
      messages: "消息",
      messageInputPlaceholder: "输入您的消息...",
      send: "发送",

      // Search Results
      searchResultsTitle: "搜索结果",
      loading: "加载中...",
      searchResultsNotFound: "未找到搜索结果。",

      // Create Listing Page
      createListingStep1: "选择类别",
      createListingStep2: "广告详情",
      createListingStep3: "属性",
      createListingStep4: "预览",
      createListingStep5: "恭喜",
      next: "继续",
      createListingTitle: "广告标题",
      createListingDescription: "描述",
      createListingUploadImages: "上传图片",
      createListingUploadImagesText: "最多可上传10张照片。",

      // Forgot Password Page
      forgotPasswordTitle: "忘记密码",
      forgotPasswordEmailInputPlaceholder: "输入您的电子邮箱",
      forgotPasswordButton: "发送密码重置链接",
      forgotPasswordGoBackLogin: "返回登录页面",

      // Login Page
      login: "登录",
      signup: "注册",
      profile: "个人资料",
      loginTitle: "登录您的账户",
      emailPlaceholder: "输入您的电子邮箱",
      passwordPlaceholder: "输入您的密码",
      loginButton: "登录",
      forgotPassword: "忘记密码？",
      signupTitle: "创建账户",
      firstNamePlaceholder: "输入您的名字",
      lastNamePlaceholder: "输入您的姓氏",
      signupButton: "注册",
      alreadyHaveAccount: "已有账户？",
      newsletterTitle: "保持更新",
      newsletterText: "订阅我们的通讯",
      subscribe: "订阅",
      supportTitle: "支持",
      contact: "联系我们",
      helpCenter: "帮助中心",
      socialTitle: "关注我们",
      languageTitle: "语言",
      categories: "类别",
      createListing: "免费发布广告",
      logout: "退出登录",
      accountInformation: "我的账户信息",
      email: "电子邮箱",
      phoneNumber: "手机号码",
      emailVerification: "邮箱验证",
      verified: "已验证",
      verify: "验证",
      membershipType: "会员类型",
      corporate: "企业",
      companyTitle: "公司名称",
      verificationCode: "验证码",
      enterVerificationCode: "请输入发送到您邮箱的验证码：",
      cancel: "取消",
      verificationCodeSendError: "验证码发送失败。",
      verificationCodeError: "验证码无效。",
      currentPassword: "当前密码",
      newPassword: "新密码",
      confirmNewPassword: "确认新密码",
      passwordsDoNotMatch: "新密码不匹配",
      passwordChangeSuccess: "密码修改成功",
      passwordChangeError: "密码修改失败",
      fetchFavoritesError: "获取收藏夹失败",
      fetchConversationsError: "获取消息失败",
      readMore: "阅读更多",
    },
  },
};

i18n
  .use(LanguageDetector) // Tarayıcı dil algılayıcı
  .use(initReactI18next) // React entegrasyonu
  .init({
    resources,
    fallbackLng: "en",
    interpolation: {
      escapeValue: false,
    },
  });

export default i18n;
