import api from "./api";
import { jwtDecode } from "jwt-decode";

export const login = async (email, password) => {
  try {
    const response = await api.post("/token/", { username: email, password });
    localStorage.setItem("access", response.data.access);
    localStorage.setItem("refresh", response.data.refresh);
    return response.data;
  } catch (error) {
    throw error.response?.data || "Login failed";
  }
};

export const logout = () => {
  localStorage.removeItem("access");
  localStorage.removeItem("refresh");
  window.location.href = "/"; // Redirect to login after logout
};

export const isAuthenticated = () => {
  return !!localStorage.getItem("access");
};

export const decodeToken = () => {
  const token = localStorage.getItem("access");

  if (!token) {
    return null;
  }
  return jwtDecode(token);
};

export const getUser = async (id) => {
  const response = await api.get(`/users/${id}/`);
  return response;
};

export const signUp = async (data) => {
  const phone = data.phone_code + data.phone;
  data.phone_number = phone;
  try {
    const response = await api.post("/register/", data);
    return response.data;
  } catch (error) {
    throw error.response?.data || "Sign up failed";
  }
};

export const changePassword = async (data) => {
  const response = await api.post("/token/change-password/", data);
  return response.data;
};

export const sendVerificationEmail = async () => {
  const response = await api.post("/send-verification-code/", {
    method: "email",
  });
  return response.data;
};

export const verifyEmail = async (code) => {
  const response = await api.post("/verify-email/", { code });
  return response.data;
};

export const sendResetPasswordEmail = async (email) => {
  const response = await api.post("/password-reset/", { email });
  return response.data;
};

export const verifyResetCode = async (uid, token, newPassword) => {
  const response = await api.post(`/password-reset-confirm/${uid}/${token}/`, {
    password: newPassword,
  });
  return response.data;
};
