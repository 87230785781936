import React, { useState, useEffect } from "react";
import { fetchCategoryAttributes } from "../api/categoryService";
import { useTranslation } from "react-i18next";

const FilterForm = ({ categorySlug, onFilter }) => {
  const { t } = useTranslation();

  const language = localStorage.getItem("i18nextLng") || "tr";

  const [attributes, setAttributes] = useState([]);
  const [filterValues, setFilterValues] = useState({}); // Kullanıcı tarafından girilen değerler

  useEffect(() => {
    const loadAttributes = async () => {
      const data = await fetchCategoryAttributes(categorySlug);
      setAttributes(data);

      // Başlangıç değerlerini ayarla
      const initialValues = {};
      data.forEach((attr) => {
        initialValues[attr.name] = ""; // Başlangıçta boş değer
      });
      setFilterValues(initialValues);
    };

    loadAttributes();
  }, [categorySlug, language]);

  const handleChange = (attributeName, value) => {
    setFilterValues((prev) => ({
      ...prev,
      [attributeName]: value,
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onFilter(filterValues); // Filtreleme fonksiyonunu çağır
  };

  return (
    <form
      onSubmit={handleSubmit}
      className="filter-form p-4 border rounded-lg shadow-md bg-white sticky top-6"
    >
      <h2 className="text-xl font-bold mb-4">{t("filterFormTitle")}</h2>
      {attributes.map((attribute) => (
        <div key={attribute.id} className="mb-4">
          <label className="block text-gray-700">
            {attribute.display_name}
          </label>
          {attribute.data_type === "number" && (
            <div>
              {attribute.filter_type === "range" && (
                <div className="flex gap-2">
                  <input
                    type="number"
                    value={filterValues[`attr__${attribute.name}__gte`]}
                    onChange={(e) =>
                      handleChange(
                        `attr__${attribute.name}__gte`,
                        e.target.value
                      )
                    }
                    className="input input-bordered w-full"
                  />
                  <input
                    type="number"
                    value={filterValues[`attr__${attribute.name}__lte`]}
                    onChange={(e) =>
                      handleChange(
                        `attr__${attribute.name}__lte`,
                        e.target.value
                      )
                    }
                    className="input input-bordered w-full"
                  />
                </div>
              )}
            </div>
          )}
          {attribute.data_type === "text" && (
            <>
              {attribute.filter_type === "choice" && (
                <select
                  value={filterValues[`attr__${attribute.name}__exact`]}
                  onChange={(e) =>
                    handleChange(
                      `attr__${attribute.name}__exact`,
                      e.target.value
                    )
                  }
                  className="select select-bordered w-full"
                >
                  <option value="">{t("filterSelectPlaceholder")}</option>
                  {attribute.choices?.map((choice) => (
                    <option key={choice.id} value={choice}>
                      {choice}
                    </option>
                  ))}
                </select>
              )}
            </>
          )}

          {attribute.data_type === "boolean" && (
            <select
              value={filterValues[attribute.name]}
              onChange={(e) => handleChange(attribute.name, e.target.value)}
              className="select select-bordered w-full"
            >
              <option value="">{t("filterSelectPlaceholder")}</option>
              <option value="true">{t("filterBooleanOptionTrue")}</option>
              <option value="false">{t("filterBooleanOptionFalse")}</option>
            </select>
          )}
        </div>
      ))}
      <button type="submit" className="btn btn-primary w-full">
        {t("filterFormButton")}
      </button>
    </form>
  );
};

export default FilterForm;
