import React, { useState, useEffect } from "react";
import { useTranslation } from "react-i18next";
import { motion } from "framer-motion";
import { signUp } from "../api/authService";
import { data, useNavigate } from "react-router-dom";
import { getCountries, getCities } from "../api/locationService";
import { toast, Toaster } from "react-hot-toast";
import { Link } from "react-router-dom";

const SignUp = () => {
  const { t } = useTranslation();
  const navigate = useNavigate();

  const [countries, setCountries] = useState([]);
  const [cities, setCities] = useState([]);
  const [selectedCountry, setSelectedCountry] = useState("");

  // Ülkeleri yükle
  useEffect(() => {
    getCountries()
      .then((data) => {
        console.log(data);
        setCountries(data);
      })
      .catch((error) => {
        console.error("Error fetching countries:", error);
      });
  }, []);

  // Şehirleri yükle
  useEffect(() => {
    if (selectedCountry) {
      getCities(selectedCountry)
        .then((data) => {
          setCities(data);
        })
        .catch((error) => {
          console.error("Error fetching cities:", error);
        });
    } else {
      setCities([]);
    }
  }, [selectedCountry]);

  const [formData, setFormData] = useState({
    first_name: "",
    last_name: "",
    email: "",
    password: "",
    phone_code: "",
    phone: "",
    country: "",
    city: "",
    membership_type: "bireysel",
    company_name: "",
    company_address: "",
    tax_number: "",
    tax_office: "",
  });

  const fadeInUp = {
    initial: { y: 20, opacity: 0 },
    animate: { y: 0, opacity: 1 },
    transition: { duration: 0.4 },
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const data_to_send = { ...formData };
      data_to_send.phone = data_to_send.phone_code + data_to_send.phone;
      await signUp(data_to_send);
      toast.success("Kayıt başarılı. Giriş sayfasına yönlendiriliyorsunuz.");
      setTimeout(() => {
        navigate("/login");
      }, 3000);
    } catch (error) {
      console.error("Kayıt hatası:", error);
    }
  };

  // Zorunlu alan göstergesi için yardımcı bileşen
  const RequiredField = () => <span className="text-red-500 ml-1">*</span>;

  return (
    <motion.div
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      className="auth-container flex justify-center items-center min-h-screen bg-gray-100 px-4 py-12"
    >
      <motion.div
        initial={{ y: 50 }}
        animate={{ y: 0 }}
        transition={{ type: "spring", stiffness: 100 }}
        className="auth-form bg-white p-8 rounded-xl shadow-xl w-full max-w-xl"
      >
        <h2 className="text-3xl font-bold mb-6 text-center text-gray-800">
          {t("signupTitle")}
        </h2>

        <div className="mb-6 text-sm text-gray-500 text-right">
          <RequiredField /> Zorunlu alanlar
        </div>

        <form onSubmit={handleSubmit} className="space-y-6">
          {/* Kişisel Bilgiler */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-gray-700 border-b pb-2">
              Kişisel Bilgiler
            </h3>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <motion.div {...fadeInUp}>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Ad <RequiredField />
                </label>
                <input
                  type="text"
                  name="first_name"
                  value={formData.first_name}
                  onChange={(e) =>
                    setFormData({ ...formData, first_name: e.target.value })
                  }
                  placeholder="Ad"
                  required
                  className="input input-bordered w-full"
                />
              </motion.div>

              <motion.div {...fadeInUp}>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Soyad <RequiredField />
                </label>
                <input
                  type="text"
                  name="last_name"
                  value={formData.last_name}
                  onChange={(e) =>
                    setFormData({ ...formData, last_name: e.target.value })
                  }
                  placeholder="Soyad"
                  required
                  className="input input-bordered w-full"
                />
              </motion.div>
            </div>

            <motion.div {...fadeInUp}>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Telefon Numarası <RequiredField />
              </label>
              <div className="flex items-center gap-2">
                <select
                  name="phone_code"
                  value={formData.phone_code}
                  onChange={(e) =>
                    setFormData({ ...formData, phone_code: e.target.value })
                  }
                  className="select select-bordered"
                >
                  {countries.map((country) => (
                    <option key={country.id} value={country.code}>
                      {country.code} (+{country.phone_code})
                    </option>
                  ))}
                </select>
                <input
                  type="tel"
                  name="phone"
                  value={formData.phone}
                  onChange={(e) =>
                    setFormData({ ...formData, phone: e.target.value })
                  }
                  placeholder="Telefon Numarası"
                  required
                  className="input input-bordered w-full"
                />
              </div>
            </motion.div>
          </div>

          {/* Konum Bilgileri */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-gray-700 border-b pb-2">
              Konum Bilgileri
            </h3>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
              <motion.div {...fadeInUp}>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Ülke <RequiredField />
                </label>
                <select
                  name="country"
                  value={formData.country}
                  onChange={async (e) => {
                    setSelectedCountry(e.target.value);
                    setFormData({ ...formData, country: e.target.value });
                  }}
                  required
                  className="input input-bordered w-full"
                >
                  <option value="">Ülke Seçiniz</option>
                  {countries?.map((country) => (
                    <option key={country.id} value={country.id}>
                      {country.name}
                    </option>
                  ))}
                </select>
              </motion.div>

              <motion.div {...fadeInUp}>
                <label className="block text-sm font-medium text-gray-700 mb-1">
                  Şehir <RequiredField />
                </label>
                <select
                  name="city"
                  value={formData.city}
                  onChange={(e) =>
                    setFormData({ ...formData, city: e.target.value })
                  }
                  required
                  className="input input-bordered w-full"
                >
                  <option value="">Şehir Seçiniz</option>
                  {cities?.map((city) => (
                    <option key={city.id} value={city.id}>
                      {city.name}
                    </option>
                  ))}
                </select>
              </motion.div>
            </div>
          </div>

          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-gray-700 border-b pb-2">
              Üyelik Türü
            </h3>

            <motion.div {...fadeInUp}>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Tür <RequiredField />
              </label>
              <select
                name="membership_type"
                value={formData.membership_type}
                onChange={(e) =>
                  setFormData({ ...formData, membership_type: e.target.value })
                }
                required
                className="input input-bordered w-full"
              >
                <option value="bireysel">Bireysel</option>
                <option value="kurumsal">Kurumsal</option>
              </select>
            </motion.div>

            {formData.membership_type === "kurumsal" ? (
              <>
                <motion.div {...fadeInUp}>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Firma Ünvanı <RequiredField />
                  </label>
                  <input
                    type="text"
                    name="company_name"
                    value={formData.company_name}
                    onChange={(e) =>
                      setFormData({ ...formData, company_name: e.target.value })
                    }
                    placeholder="Firma Ünvanı"
                    required
                    className="input input-bordered w-full"
                  />
                </motion.div>

                <motion.div {...fadeInUp}>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Firma Adresi
                  </label>
                  <input
                    type="text"
                    name="company_address"
                    value={formData.company_address}
                    onChange={(e) =>
                      setFormData({
                        ...formData,
                        company_address: e.target.value,
                      })
                    }
                    placeholder="Firma Adresi"
                    className="input input-bordered w-full"
                  />
                </motion.div>

                <motion.div {...fadeInUp}>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Vergi Dairesi <RequiredField />
                  </label>
                  <input
                    type="text"
                    name="tax_office"
                    value={formData.tax_office}
                    onChange={(e) =>
                      setFormData({ ...formData, tax_office: e.target.value })
                    }
                    placeholder="Vergi Dairesi"
                    required
                    className="input input-bordered w-full"
                  />
                </motion.div>

                <motion.div {...fadeInUp}>
                  <label className="block text-sm font-medium text-gray-700 mb-1">
                    Vergi Numarası <RequiredField />
                  </label>
                  <input
                    type="text"
                    name="tax_number"
                    value={formData.tax_number}
                    onChange={(e) =>
                      setFormData({ ...formData, tax_number: e.target.value })
                    }
                    placeholder="Vergi Numarası"
                    required
                    className="input input-bordered w-full"
                  />
                </motion.div>
              </>
            ) : null}
          </div>

          {/* Hesap Bilgileri */}
          <div className="space-y-4">
            <h3 className="text-lg font-semibold text-gray-700 border-b pb-2">
              Hesap Bilgileri
            </h3>

            <motion.div {...fadeInUp}>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                E-posta Adresi <RequiredField />
              </label>
              <input
                type="email"
                name="email"
                value={formData.email}
                onChange={(e) =>
                  setFormData({ ...formData, email: e.target.value })
                }
                placeholder="E-posta Adresi"
                required
                className="input input-bordered w-full"
              />
            </motion.div>

            <motion.div {...fadeInUp}>
              <label className="block text-sm font-medium text-gray-700 mb-1">
                Şifre <RequiredField />
              </label>
              <input
                type="password"
                name="password"
                value={formData.password}
                onChange={(e) =>
                  setFormData({ ...formData, password: e.target.value })
                }
                placeholder="Şifre"
                required
                className="input input-bordered w-full"
              />
            </motion.div>
          </div>

          <div className="flex flex-col mt-6 gap-3">
            <motion.button
              whileHover={{ scale: 1.01 }}
              whileTap={{ scale: 0.99 }}
              className="btn btn-primary w-full"
              type="submit"
            >
              {t("signupButton")}
            </motion.button>
            <span className="text-sm">
              Buraya tıklayarak,
              <Link className="text-blue-500"> gizlilik politikamızı</Link> ve
              <Link className="text-blue-500"> kullanıcı anlaşmamızı</Link>{" "}
              kabul etmiş olursunuz.
            </span>
          </div>
        </form>

        <motion.div {...fadeInUp} className="text-center mt-6">
          <p className="text-gray-600">
            {t("alreadyHaveAccount")}{" "}
            <a href="/login" className="text-blue-500 font-medium">
              {t("login")}
            </a>
          </p>
        </motion.div>
      </motion.div>
      <Toaster />
    </motion.div>
  );
};

export default SignUp;
