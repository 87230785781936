import React from "react";
import { useTranslation } from "react-i18next";
import Flag from "react-world-flags";
import { Link } from "react-router-dom";

// import icons for social media
import { FaFacebook, FaTwitter, FaInstagram } from "react-icons/fa";

const Footer = () => {
  const { t, i18n } = useTranslation();

  const changeLanguage = (lng) => {
    i18n.changeLanguage(lng);
  };

  return (
    <footer className="footer bg-[#1a202c] text-neutral-content p-10 mt-auto">
      <div>
        <span className="footer-title">{t("socialTitle")}</span>
        <div className="flex space-x-4">
          {/* <a href="/" className="link link-hover">
            <FaFacebook />
          </a> */}
          <a href="/" className="link link-hover">
            <FaTwitter />
          </a>
          <a href="/" className="link link-hover">
            <FaInstagram />
          </a>
        </div>
      </div>
      <div>
        <span className="footer-title">{t("supportTitle")}</span>
        <Link to="/contact" className="link link-hover">
          {t("contact")}
        </Link>
        <Link to="/help-center" className="link link-hover">
          {t("helpCenter")}
        </Link>
      </div>
      <div className="text-center py-4 flex justify-center items-center space-x-4">
        <Flag
          code="GB"
          alt="English"
          className="w-6 h-4 cursor-pointer"
          onClick={() => changeLanguage("en")}
        />
        <Flag
          code="TR"
          alt="Türkçe"
          className="w-6 h-4 cursor-pointer"
          onClick={() => changeLanguage("tr")}
        />
      </div>
    </footer>
  );
};

export default Footer;
