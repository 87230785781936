import { useEffect, useState } from "react";
import { DragDropContext, Droppable, Draggable } from "react-beautiful-dnd";
import { useTranslation } from "react-i18next";

export const StrictModeDroppable = ({ children, ...props }) => {
  const [enabled, setEnabled] = useState(false);
  useEffect(() => {
    const animation = requestAnimationFrame(() => setEnabled(true));
    return () => {
      cancelAnimationFrame(animation);
      setEnabled(false);
    };
  }, []);
  if (!enabled) {
    return null;
  }
  return <Droppable {...props}>{children}</Droppable>;
};

const ImageUploader = ({ data, setData }) => {
  const { t } = useTranslation();

  const [images, setImages] = useState([]); // Seçilen resimler
  const [error, setError] = useState(""); // Hata mesajı
  const MAX_IMAGES = 10; // Maksimum resim sınırı

  const handleFileChange = (e) => {
    const files = Array.from(e.target.files);

    if (files.length + images.length > MAX_IMAGES) {
      setError(t("createListingUploadImagesText"));
      return;
    }

    setError(""); // Hata mesajını sıfırla

    const newImages = files.map((file) => ({
      id: Date.now() + Math.random(), // Benzersiz ID
      file,
      url: URL.createObjectURL(file),
    }));

    const updatedImages = [...images, ...newImages];

    setImages(updatedImages);
    setData((prevData) => ({
      ...prevData,
      images: updatedImages,
    }));
  };

  const removeImage = (index) => {
    const updatedImages = images.filter((_, i) => i !== index);
    setImages(updatedImages);
    setError(""); // Hata mesajını sıfırla

    setData((prevData) => ({
      ...prevData,
      images: updatedImages,
    }));
  };

  const onDragEnd = (result) => {
    if (!result.destination) return;

    const newImages = Array.from(images);
    const [movedImage] = newImages.splice(result.source.index, 1);
    newImages.splice(result.destination.index, 0, movedImage);

    console.log("New images:", newImages);
    setImages(newImages);
    setData((prevData) => ({
      ...prevData,
      images: newImages,
    }));
  };

  return (
    <div>
      <p className="text-gray-600 text-sm mb-2">
        {t("createListingUploadImagesText")}
      </p>

      {/* Drag & Drop Context */}
      <DragDropContext onDragEnd={onDragEnd}>
        <StrictModeDroppable droppableId="image-list" direction="horizontal">
          {(provided) => (
            <div
              className="flex gap-2 flex-wrap"
              ref={provided.innerRef}
              {...provided.droppableProps}
            >
              {images.map((img, index) => (
                <Draggable
                  key={img.id}
                  draggableId={img.id.toString()}
                  index={index}
                >
                  {(provided) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      className="relative w-36 h-36 border rounded-md cursor-grab"
                    >
                      <img
                        src={img.url}
                        alt={`Preview ${index + 1}`}
                        className="w-full h-full object-cover rounded-md"
                      />
                      <button
                        onClick={() => removeImage(index)}
                        className="absolute top-0 right-0 bg-red-500 text-white p-1 m-1 rounded-full text-xs"
                      >
                        X
                      </button>
                    </div>
                  )}
                </Draggable>
              ))}

              {provided.placeholder}

              {images.length < MAX_IMAGES && (
                <div
                  onClick={() => document.getElementById("imageInput").click()}
                  className="w-36 h-36 border-2 border-dashed flex items-center justify-center text-gray-500 cursor-pointer rounded-md"
                >
                  +
                </div>
              )}
            </div>
          )}
        </StrictModeDroppable>
      </DragDropContext>

      {/* Dosya yükleme butonu */}
      <input
        id="imageInput"
        type="file"
        accept="image/*"
        multiple
        onChange={handleFileChange}
        className="hidden"
      />

      {/* Hata mesajı */}
      {error && <p className="text-red-500 mt-2">{error}</p>}
    </div>
  );
};

export default ImageUploader;
