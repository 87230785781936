import React from "react";
import { Link } from "react-router-dom";
import { GiCrane } from "react-icons/gi";
// import categorycard.css
import "./categorycard.css";

const CategoryCard = ({ sub }) => {
  return (
    <Link to={`/category/${sub.slug}/`}>
      <div
        className="w-full bg-white rounded-lg shadow p-4 text-center border 
        border-gray-200 
        hover:shadow-lg 
        hover:bg-gradient-to-r
        hover:from-blue-600 
        hover:to-blue-800
        hover:text-white 
        transition-all 
        duration-300 ease-out"
      >
        <img
          src={sub.image}
          alt={sub.name}
          className="w-auto h-32 mx-auto mb-2 move-2"
        />
        <h3 className="text-lg font-medium mb-1">{sub.name}</h3>
        <span className="text-sm">({sub.machine_count})</span>
      </div>
    </Link>
  );
};

export default CategoryCard;
